import React from "react";
import { Nav, Navbar, NavDropdown, Button } from "react-bootstrap";
import { FaSignOutAlt, FaUser, FaSearch, PiShareFatLight, FaShare } from "react-icons/fa";
import MentionInputField from "./drop";
import "./navbar.scss";
import navLogo from "../../assets/ui/Logo SVG.svg";
import sdelelogo from "../../assets/logo/sdalelogo.png";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { azureAdEmailCheck } from "../api/accountAction";
import ceoimg from "../../assets/ceo.png";
import { Icon } from "@iconify/react";

function NavBar() {
  const navigateFn = useNavigate();
  const dispatch = useDispatch();
  const tag_id = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.tags")
  );
  const logout = () => {
    const account_type = atob(
      localStorage.getItem("AIVolveXIdentityServiceProvider.logtype")
    );
    const costomer_email = atob(
      localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
    );

    // navigateFn(
    //   "https://login.microsoftonline.com/1e0b7619-b37d-4b53-a46b-35a1180b4632/oauth2/logout?post_logout_redirect_uri=https://www.dev.aivolvex.ai/"
    // );
    // navigateFn(
    //   "https://azureaivolex.auth.ap-south-1.amazoncognito.com/logout?client_id=tbc80j9rr00uvg7rihougtd7h&logout_uri=https://www.dev.aivolvex.ai/logout"
    // );

    if (account_type === "azure") {
      const email = costomer_email;
      dispatch(azureAdEmailCheck(email))
        .unwrap()
        .then(({ data }) => {
          const records = JSON.parse(data.company_check);
          // console.log(records);
          const client_id = records.azureclient_id;
          const tenantid = records.tenant_id;
          const cognito_client_id = records.cognitoclient_id;

          localStorage.removeItem("AIVolveXIdentityServiceProvider.com");
          localStorage.removeItem("AIVolveXIdentityServiceProvider.logtype");
          localStorage.removeItem("AIVolveXIdentityServiceProvider.auth");

          window.open(
            "https://login.microsoftonline.com/" +
            tenantid +
            "/oauth2/logout?post_logout_redirect_uri=https://www.dev.aivolvex.ai/",
            "_blank"
          );
          window.location.href =
            "https://azureaivolex.auth.ap-south-1.amazoncognito.com/logout?client_id=" +
            cognito_client_id +
            "&logout_uri=https://www.dev.aivolvex.ai/logout";

          // window.location.href =
          //   "https://login.microsoftonline.com/" +
          //   tenantid +
          //   "/oauth2/logout?post_logout_redirect_uri=https://azureaivolex.auth.ap-south-1.amazoncognito.com/logout?client_id=" +
          //   cognito_client_id +
          //   "&logout_uri=https://www.dev.aivolvex.ai/logout";
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      localStorage.removeItem("AIVolveXIdentityServiceProvider.com");
      localStorage.removeItem("AIVolveXIdentityServiceProvider.logtype");
      localStorage.removeItem("AIVolveXIdentityServiceProvider.auth");
      window.location.href = "/";
      // navigateFn("/");
    }
    // console.log(account_type);
    // navigateFn("/")
  };
  return (
    <div>
      <Navbar
        className={
          tag_id === "all"
            ? "nav-height shadow desktopnav"
            : "nav-height shadow desktopnav sdale-nav"
        }
        collapseOnSelect
        expand="lg"
      >
        <Navbar.Brand className="navImage">
          {tag_id === "all" ? (
            <>
              {" "}
              <img
                src={navLogo}
                alt="nav-logo "
                className="navlogo cursor-pointer"
                onClick={() => {
                  navigateFn("/avatarhub/enterprise");
                }}
              />
              <span className="logo-peta">beta</span>
            </>
          ) : (
            <>
              <img
                src={sdelelogo}
                alt="nav-logo "
                className="sdelalogo cursor-pointer"
                onClick={() => {
                  navigateFn("/avatarhub/enterprise");
                }}
              />
              <img
                src={navLogo}
                alt="nav-logo "
                className="navlogo cursor-pointer"
                onClick={() => {
                  navigateFn("/avatarhub/enterprise");
                }}
              />
              <span className="logo-peta">beta</span>
            </>
          )}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />

        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <div></div>
          </Nav>

          <Nav>
            <MentionInputField /> &nbsp;&nbsp;
            <Nav.Link>
              <NavDropdown
                title={
                  <div>
                    <Icon
                      icon="ic:round-notifications"
                      className="nav-notification-bell"
                    />
                  </div>
                }
                className="nav-dropdown"
              >
                <NavDropdown.Item>
                <Icon
            icon="icons8:share"
            className="share-icon"
            
          /> &nbsp; Share
                </NavDropdown.Item>
              </NavDropdown>

            </Nav.Link>
            <Nav.Link>
              <NavDropdown
                title={
                  <div>
                    {tag_id === "all" ? (
                      <img src={ceoimg} alt="" className="chat-avator-img" />
                    ) : (
                      <Icon
                        icon="healthicons:ui-user-profile"
                        className="chat-avator-img"
                        style={{ color: "#fff" }}
                      />
                    )}
                  </div>
                }
                id="basic-nav-dropdown"
                // className={{ position: "relative", right: "2.5rem" }}
                className="nav-dropdown"
              >
                <NavDropdown.Item>
                  <FaUser /> &nbsp; demo
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={() => logout()}>
                  <span>
                    <FaSignOutAlt /> &nbsp; Logout
                  </span>
                </NavDropdown.Item>
              </NavDropdown>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

export default NavBar;
