import { memo, useEffect, useState } from "react";
import "./Myprofile.scss";
import { Icon } from "@iconify/react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { getTokenRemaining } from "../api/accountAction";
import { useDispatch } from "react-redux";
import aivolvex from "../../assets/ui/X Icon SVG.svg";
import { IoShuffleOutline } from "react-icons/io5";
import { IoIosKeypad, IoIosTimer } from "react-icons/io";

const Sidemenu = (props) => {
  const [page, setpage] = useState("library");
  const [toggled, setToggled] = useState(false);
  const [avatarhubstatus, setAvatarHubStatus] = useState(false);
  const [sidebarmenu, setSidebarMenu] = useState(1);
  const [fullpath, setFullPath] = useState("");
  const dispatch = useDispatch();
  const [token, setToken] = useState(0);
  const companyName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.com")
  );

  useEffect(() => {
    setFullPath(window.location.pathname);
    if (window.location.pathname === "/avatarhub/enterprise") {
      setSidebarMenu(1);
      setAvatarHubStatus(true);
    } else if (window.location.pathname === "/dashboard") {
      setSidebarMenu(2);
      setAvatarHubStatus(false);
    } else if (window.location.pathname === "/history") {
      setSidebarMenu(4);
      setAvatarHubStatus(false);
    } else if (window.location.pathname === "/recent_prompts") {
      setSidebarMenu(3);
      setAvatarHubStatus(false);
    } else if (window.location.pathname === "/favorites") {
      setSidebarMenu(5);
      setAvatarHubStatus(false);
    } else if (window.location.pathname === "/avatarhub/general") {
      setSidebarMenu(6);
    } else if (window.location.pathname === "/tag") {
      setSidebarMenu(7);
    }
  }, [window.location.pathname, props]);
  useEffect(() => {
    console.log(avatarhubstatus);
  }, [avatarhubstatus]);
  useEffect(() => {
    const input = {
      company: companyName,
    };
    dispatch(getTokenRemaining(input))
      .unwrap()
      .then(({ data }) => {
        const records = JSON.parse(data.aivolvex_general_prompts);
        setToken(records.remaining_credits);
      })
      .catch((err) => {
        console.log(err);
        // message.error(err);
      });
  }, []);
  return (
    <>
      <div className="sidebar-mobile">
        <div className="mobile-menu" onClick={() => setToggled(!toggled)}>
          <div class="bar"></div>
          <div class="bar"></div>
          <div class="bar"></div>
        </div>
      </div>

      <Sidebar className="costimize-sidebar sidebar-desktop">
        <Menu>
          <MenuItem
            className={sidebarmenu === 1 && "active"}
            component={<Link to="/avatarhub/enterprise" />}
          >
            <Icon icon="gg:profile" id="library" className="DashboardNavicon" />
            <span className="sidebar-content">Avatar Hub</span>
          </MenuItem>
          <MenuItem
            className={sidebarmenu === 6 && "active"}
            component={<Link to="/avatarhub/general" />}
          >
            <Icon
              icon="icon-park-outline:avatar"
              id="library"
              className="DashboardNavicon"
            />
            <span className="sidebar-content">AIVolveX Hub</span>
          </MenuItem>

          {/* <SubMenu
            className={sidebarmenu === 1 && "active"}
            defaultOpen={false}
            label={
              <>
                <Icon
                  icon="gg:profile"
                  id="library"
                  className="DashboardNavicon"
                />
                <span className="sidebar-content">AvatarHub</span>
              </>
            }
          >
            <MenuItem
              className={fullpath === "/avatarhub/enterprise" && "active"}
              component={<Link to="/avatarhub/enterprise" />}
            >
              Enterprise
            </MenuItem>
            <MenuItem
              className={fullpath === "/avatarhub/general" && "active"}
              component={<Link to="/avatarhub/general" />}
            >
              General
            </MenuItem>
          </SubMenu> */}
          <MenuItem
            className={sidebarmenu === 2 && "active"}
            component={<Link to="/dashboard" />}
          >
            <IoIosKeypad className="DashboardNavicon" />
            <span className="sidebar-content">Dashboard</span>
          </MenuItem>
          <MenuItem
            className={sidebarmenu === 3 && "active"}
            component={<Link to="/recent_prompts" />}
          >
            <IoShuffleOutline className="DashboardNavicon" />

            <span className="sidebar-content">Recent Prompts</span>
          </MenuItem>
          <MenuItem
            className={sidebarmenu === 4 && "active"}
            component={<Link to="/history" />}
          >
            <IoIosTimer className="DashboardNavicon" />
            <span className="sidebar-content">History</span>
          </MenuItem>
          <MenuItem
            className={sidebarmenu === 5 && "active"}
            component={<Link to="/favorites" />}
          >
            <Icon icon="uil:favorite" className="DashboardNavicon" />
            <span className="sidebar-content">Favorites</span>
          </MenuItem>

          {/* <MenuItem
            className={sidebarmenu === 7 && "active"}
            component={<Link to="/tag" />}
          >
            <Icon
              icon="eos-icons:role-binding-outlined"
              className="DashboardNavicon"
            />
            <span className="sidebar-content">Tags</span>
          </MenuItem> */}
        </Menu>
        <div className="sidebar-footer-body">
          <div className="sidebar-footer">
            <div className="d-flex justify-content-between">
              <div>AIVolveX Tokens </div>
              <div>
                <img src={aivolvex} alt="logo" className="token-footer-logo" />{" "}
                {token}
              </div>
            </div>
          </div>
          <div className="sidebar-footer-version">V 0.8.1</div>
        </div>
      </Sidebar>
      {/* mobile view */}
      <Sidebar
        onBackdropClick={() => setToggled(false)}
        toggled={toggled}
        breakPoint="always"
        className="costimize-sidebar sidebar-mobile"
      >
        <Menu>
          <MenuItem
            className={sidebarmenu === 1 && "active"}
            component={<Link to="/avatarhub/enterprise" />}
          >
            <Icon icon="gg:profile" id="library" className="DashboardNavicon" />
            {/* <Icon
              icon="mdi:library-outline"
              id="library"
              className="DashboardNavicon"
            /> */}
            {/* <span className="sidebar-content">ThoughtHub</span> */}
            <span className="sidebar-content">Avatar Hub</span>
          </MenuItem>
          <MenuItem
            className={sidebarmenu === 2 && "active"}
            component={<Link to="/dashboard" />}
          >
            {/* <Icon
              icon="ri:dashboard-line"
              id="dashboard"
              className="DashboardNavicon"
            /> */}
            <IoIosKeypad className="DashboardNavicon" />
            <span className="sidebar-content">Dashboard</span>
          </MenuItem>
          <MenuItem
            className={sidebarmenu === 3 && "active"}
            component={<Link to="/recent_prompts" />}
          >
            {/* <Icon
              icon="material-symbols:recent-actors-outline"
              id="library"
              className="DashboardNavicon"
            /> */}
            {/* <Icon
              icon="ooui:recent-changes-ltr"
              id="library"
              className="DashboardNavicon"
            /> */}
            <IoShuffleOutline className="DashboardNavicon" />

            <span className="sidebar-content">Recent Prompts</span>
          </MenuItem>
          <MenuItem
            className={sidebarmenu === 4 && "active"}
            component={<Link to="/history" />}
          >
            {/* <Icon
              icon="mdi:library-outline"
              id="library"
              className="DashboardNavicon"
            /> */}
            {/* <Icon
              icon="material-symbols:history"
              className="DashboardNavicon"
            /> */}
            <IoIosTimer className="DashboardNavicon" />
            <span className="sidebar-content">History</span>
          </MenuItem>
          <MenuItem
            className={sidebarmenu === 5 && "active"}
            component={<Link to="/favorites" />}
          >
            {/* <Icon
              icon="mdi:library-outline"
              id="library"
              className="DashboardNavicon"
            /> */}
            <Icon icon="uil:favorite" className="DashboardNavicon" />
            <span className="sidebar-content">Favorites</span>
          </MenuItem>

          {/* <MenuItem
            className={sidebarmenu === 2 && "active"}
            //   component={<Link to="/costdemaze/quick_glance" />}
          >
            <Icon
              icon="material-symbols:history"
              id="library"
              className="DashboardNavicon"
            />{" "}
            History
          </MenuItem> */}
        </Menu>
        <div className="sidebar-footer-body">
          <div className="sidebar-footer">
            <div className="d-flex justify-content-between">
              <div>AIVolveX Tokens </div>
              <div>
                <img src={aivolvex} alt="logo" className="token-footer-logo" />{" "}
                {token}
              </div>
            </div>
          </div>
          <div className="sidebar-footer-version">V 0.8.1</div>
        </div>
      </Sidebar>
    </>
  );
};

export default memo(Sidemenu);
