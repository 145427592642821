import React from "react";
import NavBar from "../Header/NavBar";
import { Col, DatePicker, Row } from "antd";
import Sidemenu from "../Sidemenu/Sidemenu";
import "./dashboard.scss";
import earth from "../../assets/dashboard/Folder.webp";
import calendar from "../../assets/dashboard/Bookmark.webp";
import ongoing from "../../assets/dashboard/Clock.webp";
import tokenimg from "../../assets/dashboard/nft_6298532.png";
import avatarimg from "../../assets/dashboard/scientist_3587150.png";
import ReactApexChart from "react-apexcharts";
// import Dropdown from "react-bootstrap/Dropdown";
import { Button, Dropdown } from "antd";
import { Icon } from "@iconify/react";
import { useState } from "react";
const Dashboard = () => {
  const data = {
    series: [
      {
        name: "Sales",
        data: [3, 2, 5],
      },
    ],
    options: {
      chart: {
        type: "bar",
        toolbar: {
          show: false, 
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "40%", // Adjust the width of columns (default is 70%)
        },
      },
      xaxis: {
        categories: ["Pod 1", "Pod 2", "Pod 3"],
        style: {
          fontSize: "8px",
          fontFamily: "Axiforma, Arial, sans-serif", // Use Axiforma as the primary font
          fontWeight: "normal",
        },
        title: {
          text: "Pods", // Y-axis label
        },
      },
      yaxis: {
        title: {
          text: "Avatars", // Y-axis label
        },
      },
      toolkit: {
        show: false, 
      },
    },
  };


  const countData = {
  
    series: [
      {
        name: "Prompt Count",
        data: [
          ["2023-07-04", 20],
          ["2023-07-05", 40],
          ["2023-07-06", 30],
          ["2023-07-07", 15],
          ["2023-07-08", 18],
          ["2023-07-09", 27],
          ["2023-07-10", 22],
          ["2023-07-11", 33],
          ["2023-07-12", 12],
          ["2023-07-13", 28],
          ["2023-07-14", 19],
          ["2023-07-15", 24],
          ["2023-07-16", 37],
          ["2023-07-17", 23],
          ["2023-07-18", 31],
          ["2023-07-19", 16],
          ["2023-07-20", 21],
          ["2023-07-21", 29],
          ["2023-07-22", 36],
          ["2023-07-23", 17],
          ["2023-07-24", 26],
          ["2023-07-25", 32],
          ["2023-07-26", 13],
          ["2023-07-27", 38],
          ["2023-07-28", 14],
          ["2023-07-29", 39],
          ["2023-07-30", 11],
          ["2023-07-31", 34],
          ["2023-08-01", 10],
          ["2023-08-02", 25],
          ["2023-08-03", 35],
        ],
      },
      // {
      //   name: 'Token Count',
      //   data: [

      //     ['2023-07-04', 10],
      //     ['2023-07-05', 30],
      //     ['2023-07-06', 28],
      //     ['2023-07-07', 19],
      //     ['2023-07-08', 12],
      //     ['2023-07-09', 22],
      //     ['2023-07-10', 22],
      //     ['2023-07-11', 32],
      //     ['2023-07-12', 12],
      //     ['2023-07-13', 22],
      //     ['2023-07-14', 11],
      //     ['2023-07-15', 21],
      //     ['2023-07-16', 31],
      //     ['2023-07-17', 21],
      //     ['2023-07-18', 30],
      //     ['2023-07-19', 11],
      //     ['2023-07-20', 11],
      //     ['2023-07-21', 9],
      //     ['2023-07-22', 16],
      //     ['2023-07-23', 27],
      //     ['2023-07-24', 16],
      //     ['2023-07-25', 12],
      //     ['2023-07-26', 23],
      //     ['2023-07-27', 30],
      //     ['2023-07-28', 15],
      //     ['2023-07-29', 30],
      //     ['2023-07-30', 16],
      //     ['2023-07-31', 39],
      //     ['2023-08-01', 5],
      //     ['2023-08-02', 5],
      //     ['2023-08-03', 30],
      //   ],
      // },
    ],
    
    options: {
      chart: {
        type: "area",
        toolbar: {
          show: false, 
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "40%", // Adjust the width of columns (default is 70%)
        },
      },
      xaxis: {
        type: "datetime", // Set x-axis type to datetime for dates
        min: new Date("2023-07-04").getTime(), // Minimum date of the month
        max: new Date("2023-08-03").getTime(), // Maximum date of the month
        labels: {
          style: {
            colors: "#75768a",
          },
        },
      },
      color: ["#517ead"],
      yaxis: {
        // title: {
        //   text: 'Prompt Count',
        // },
        labels: {
          style: {
            colors: "#75768a",
          },
        },
      },
      dataLabels: {
        enabled: false, // Hide the data points in the area chart
      },
    },
  };

  const promtData = {
    series: [
      {
        name: "Sales",
        data: [44, 32, 12],
      },
    ],
    options: {
      chart: {
        type: "bar",
        toolbar: {
          show: false,
        },
      },

      plotOptions: {
        bar: {
          columnWidth: "40%",
        },
      },
      // colors: ['#FFC107'],
      colors: ["#e37d05"],
      dataLabels: {
        enabled: false,
      },
      title: {
        text: 'Prompts',
        align:"center",
        style: {
          // fontFamily: 'axiforma', 
          fontWeight: "lighter",
          
        }

      },
      xaxis: {
        // categories: ['Customer Enquiry', 'Loan Application', 'Pricing', 'Customer Service'],
        categories: ["Jun-23", "Jul-23", "Aug-23"],
        // title: {
        //   text: 'Avatars',
        // },
        lines: {
          show: false,
        },
        labels: {
          style: {
            colors: "#75768a",
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#75768a",
          },
        },
        // title: {
        //   text: 'Prompts',
        // },
      },
    },
  };
  const promtData2 = {
    series: [
      {
        name: "Sales",
        data: [12],
      },
    ],
    options: {
      chart: {
        type: "bar",
        toolbar: {
          show: false,
        },
      },

      plotOptions: {
        bar: {
          columnWidth: "40%",
        },
      },
      colors: ["#FFC107"],
      xaxis: {
        categories: ["SWO Creator"],
        // title: {
        //   text: 'Avatars',
        // },
        lines: {
          show: false,
        },
        labels: {
          style: {
            colors: "#75768a",
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#75768a",
          },
        },
        // title: {
        //   text: 'Prompts',
        // },
      },
    },
  };
  const promtData3 = {
    series: [
      {
        name: "Sales",
        data: [10, 11, 11, 11],
      },
    ],
    options: {
      chart: {
        type: "bar",
        toolbar: {
          show: false,
        },
      },

      plotOptions: {
        bar: {
          columnWidth: "40%",
        },
      },
      colors: ["#FFC107"],
      xaxis: {
        categories: [
          "SupportMate",
          "Pre Sales Operation ",
          "CloudShifu",
          "PMO Avatar",
        ],
        // title: {
        //   text: 'Avatars',
        // },
        lines: {
          show: false,
        },
        labels: {
          style: {
            colors: "#75768a",
          },
        },
      },
      yaxis: {
        title: {
          text: "Prompts",
        },
        labels: {
          style: {
            colors: "#75768a",
          },
        },
      },
    },
  };
  const tokenData = {
    series: [
      {
        name: "Sales",
        data: [5, 1, 3],
      },
    ],
    options: {
      chart: {
        type: "bar",
        toolbar: {
          show: false, // Disable the entire toolbar menu
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "40%", // Adjust the width of columns (default is 70%)
        },
      },
      colors: ["#A52A2A"],

      xaxis: {
        categories: ["CloudShifu", "Support Mate", "Loan Application"],
        labels: {
          style: {
            colors: "#75768a",
          },
        },

        // title: {
        //   text: "Avatars", // Y-axis label
        // },
      },
      yaxis: {
        // title: {
        //   text: "Tokens", // Y-axis label
        // },
        colors: ["#00cc00"],
        labels: {
          style: {
            colors: "#75768a",
          },
        },
      },
    },
  };

  const [selectedMonth, setSelectedMonth] = useState();

  const handleMonthChange = (value) => {
    setSelectedMonth(value);
  };
  const seriesData = [
    {
      name: "Customer Enquiry",
      data: [44, 0, 0],
    },
    {
      name: "Loan Application",
      data: [32, 0, 0],
    },
    {
      name: "Pricing",
      data: [12, 0, 0],
    },
    {
      name: "Customer Service",
      data: [9, 0, 0],
    },
    {
      name: "SWO Creator",
      data: [0, 12, 0],
    },
    {
      name: "Support Mate",
      data: [0, 0, 10],
    },
    {
      name: "Pre Sales Operations",
      data: [0, 0, 11],
    },
    {
      name: "CloudShifu",
      data: [0, 0, 11],
    },
    {
      name: "PMO Avatar",
      data: [0, 0, 11],
    },
  ];

  const options = {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          enabled: false,
          // total: {
          //   enabled: true,
          //   offsetX: 0,
          //   style: {
          //     fontSize: '13px',
          //     fontWeight: 900,
          //   },
          // },
        },
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    dataLabels: {
      enabled: false,
    },
    title: {
      text: 'Pods vs Token',
      align:"center",
      style: {
     
        fontWeight: "lighter",
        
      }
    },
    xaxis: {
      categories: [
        "Customer Operation",
        "Pre Sales Operation",
        "IT Operations",
      ],
      labels: {
        formatter: function (val) {
          return val + "K";
        },
        style: {
          colors: "#75768a",
        },
      },
      title: {
        text: "Token",
        style: {
          fontWeight: "lighter",
          
        }
      },
    },
    yaxis: {
      title: {
        text: "Pods",
        style: {
          fontWeight: "lighter",
          
        }
      },
      labels: {
        style: {
          colors: "#75768a",
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + "K";
        },
      },
    },
    colors: [
      "#34495E",
      "#11104E",
      "#F7CB15",
      "#7CC4E6",
      "#CC8EE2",
      "#97DB4F",
      "#3b2a22",
      "#00ACC1",
      "#757575",
    ],
    fill: {
      opacity: 1,
    },
    legend: {
      // position: 'top',
      // horizontalAlign: 'left',
      // offsetX: 40,
      show: false,
    },
  };

  const responseData = {
    series: [
      {
        name: "Positive",
        data: [30, 40, 45],
      },
      {
        name: "Negative",
        data: [0, 0, 0],
      },
    ],
    options: {
      chart: {
        type: "bar",
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "40%",
        },
      },
      xaxis: {
        // categories: ["CloudShifu", "Support Mate", "Loan Application"],
        categories: ["Avatar 1", "Avatar 2", "Avatar 3"],
      },
      yaxis: {
        title: {
          text: "Prompt Responses",
        },
      },
      colors: ["#5e96c5", "#5e96c5"], // Green and Red colors for Positive and Negative respectively
    },
  };
  const [chart1, setChart1] = useState(true);
  const [chart2, setChart2] = useState(false);
  const [chart3, setChart3] = useState(false);
  const view1 = (value) => {
    console.log(value);
    setChart1(true);
    setChart2(false);
    setChart3(false);
  };
  const view2 = (value) => {
    console.log(value);
    setChart1(false);
    setChart2(true);
    setChart3(false);
  };

  const view3 = (value) => {
    console.log(value);
    setChart1(false);
    setChart2(false);
    setChart3(true);
  };
  const items = [
    {
      key: "1",
      label: "Token",
    },
    {
      key: "2",
      label: "Prompts",
    },
  ];
  console.log(countData.series);

  return (
    <>
      <NavBar />
      <div className="body-bg">
        <Row>
          <Col span={5} className="window-margin-right  body-margin-top">
            <Sidemenu />
          </Col>
          <Col span={18} className="body-margin-top">
            <Row className="mt-4">
              <Col span={24} className="d-flex justify-content-between">
                <h4 className="white-color">Dashboard</h4>
                <DatePicker.MonthPicker
                  className="dashboard-datepicker"
                  onChange={handleMonthChange}
                  value={selectedMonth}
                />
              </Col>
            </Row>
            <Row className="mt-1">
              <Col span={24}>
                <div className="Dashboard_head">
                  <div className="Dashboard_head_box Dashboard_head_box_shadow">
                    <div className="Dashboard_head_content">
                      <h2> 3/3</h2>
                      <p>Pod Access</p>
                    </div>
                    <img src={earth} alt="" />
                  </div>
                  <div className="Dashboard_head_box Dashboard_head_box_shadow">
                    <div className="Dashboard_head_content">
                      <h2> 5</h2>
                      <p>Used Prompts</p>
                    </div>
                    <img src={calendar} alt="" />
                  </div>
                  <div className="Dashboard_head_box Dashboard_head_box_shadow">
                    <div className="Dashboard_head_content">
                      {/* <h2>{dashboardResponseData.jobsAppliedCount}</h2> */}
                      <h2> 5</h2>
                      <p>No. of Pods</p>
                    </div>
                    <img src={ongoing} alt="" />
                  </div>
                  <div className="Dashboard_head_box Dashboard_head_box_shadow">
                    <div className="Dashboard_head_content">
                      {/* <h2>{dashboardResponseData.jobsAppliedCount}</h2> */}

                      <h2>8</h2>
                      <p>No. of Avatars</p>
                    </div>
                    <img src={avatarimg} alt="" />
                  </div>
                  <div className="Dashboard_head_box Dashboard_head_box_shadow">
                    <div className="Dashboard_head_content">
                      {/* <h2>{dashboardResponseData.jobsAppliedCount}</h2> */}
                      <h2>900/1K</h2>
                      <p>Token Used</p>
                    </div>
                    <img src={tokenimg} alt="" />
                  </div>
                </div>
              </Col>
            </Row>
            <Row
              className="mt-3 d-flex justify-content-between"
              gutter={[16, 16, 16, 16]}
            >
              <Col span={12}>
                <div className="Dashboard_graph_box Dashboard_head_box_shadow">
                  <div className="Dashboard_head_content">
                    <div id="chart">
                      {/* <ReactApexChart
                        options={data.options}
                        series={data.series}
                        type="bar"
                        height={200}
                      /> */}
                      <ReactApexChart
                        options={options}
                        series={seriesData}
                        type="bar"
                        height={220}
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className="Dashboard_graph_box Dashboard_head_box_shadow">
                  <div className="Dashboard_head_content">
                    <div id="chart">
                      <div>
                        <Dropdown
                          menu={{
                            items,
                          }}
                          placement="bottomRight"
                          arrow
                        >
                          <Icon
                            className="chart-dropdown"
                            icon="mdi:arrow-down-drop"
                          />
                        </Dropdown>
                      </div>
                      <>
                        {chart1 ? (
                          <>
                            <ReactApexChart
                              options={promtData.options}
                              series={promtData.series}
                              type="bar"
                              height={200}
                              toolbar={false}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                        {chart2 ? (
                          <>
                            <ReactApexChart
                              options={promtData2.options}
                              series={promtData2.series}
                              type="bar"
                              height={200}
                              toolbar={false}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                        {chart3 ? (
                          <>
                            <ReactApexChart
                              options={promtData3.options}
                              series={promtData3.series}
                              type="bar"
                              height={200}
                              toolbar={false}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    </div>
                  </div>
                </div>
              </Col>
             
            </Row>
            <Row
              className="mt-3 d-flex justify-content-between"
              gutter={[16, 16]}
            >
            
            </Row>
            <Row>
              <Col span={24}>
                <div className="Dashboard_count_graph Dashboard_head_box_shadow">
                  <div className="Dashboard_head_content">
                    <ReactApexChart
                    title="Prompts Usage"
                      options={countData.options}
                      series={countData.series}
                      type="area"
                      height={190}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Dashboard;
