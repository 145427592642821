import { Icon } from "@iconify/react";
import { Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import "./breadcrumb.css";

const BreadCrumb = (props) => {
  const navigateFn = useNavigate();
  return (
    <>
      <Row className="mt-2 mb-2">
        <Col span={24}>
          <div>
            <h3 className="chat-title aivolvex-font">
              <Icon
                icon="cil:home"
                className="bredcrumb-chat-home-icon"
                onClick={() => {
                  navigateFn(props.homelink);
                }}
              />{" "}
              {props.breadcrumblist.length > 0 && (
                <>
                  {props.breadcrumblist.map((data) => {
                    return (
                      <>
                        {console.log(data)}
                        <Icon
                          icon="iconamoon:arrow-right-2-light"
                          className="bredcrumb-promp-arrow"
                        />{" "}
                        <span
                          onClick={() => {
                            navigateFn(data.link);
                          }}
                          className="cursor-pointer breadcrumb-title"
                        >
                          {data.name}
                        </span>
                      </>
                    );
                  })}
                </>
              )}
              <Icon
                icon="iconamoon:arrow-right-2-light"
                className="bredcrumb-promp-arrow"
              />{" "}
              <span className="promp-active breadcrumb-title">
                {props.activebreadcroum.name}
              </span>
            </h3>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default BreadCrumb;
