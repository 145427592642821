import { memo, useState, useEffect } from "react";
import NavBar from "../Header/NavBar";
import Sidemenu from "../Sidemenu/Sidemenu";
import { Col, Row, Tabs } from "antd";
import "./library.css";
import AvatorList from "./AvatorList";
import AvatorList2 from "./AvatorList2";
import AvatorList3 from "./AvatorList3";
import { listAvatar } from "../api/accountAction";
import { useDispatch } from "react-redux";
import aivolvex from "../../assets/ui/X Icon SVG.svg";

const Library = (props) => {
  const [basicActive, setBasicActive] = useState("0");
  const dispatch = useDispatch();
  const [podlist, setPodList] = useState([]);
  const [records, setRecords] = useState([]);
  const [loader, setLoader] = useState(true);
  const companyName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.com")
  );
  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }
    setBasicActive(value);
  };
  useEffect(() => {
    // console.log(companyName);
    setLoader(true);
    const tag_id = atob(
      localStorage.getItem("AIVolveXIdentityServiceProvider.tags")
    );
    console.log(tag_id);

    const input = {
      company_name: companyName,
      tag_id: tag_id,
    };
    dispatch(listAvatar(input))
      .unwrap()
      .then(({ data }) => {
        setLoader(false);
        // setUpdateStatus(!updatestatus);
        if (JSON.parse(data.aivolvex_general_prompts).length > 0) {
          // console.log(props.pagetype);
          // console.log(JSON.parse(data.aivolvex_general_prompts));
          const all_pod_list = [];
          if (props.pagetype === "enterprice") {
            var podlist = JSON.parse(data.aivolvex_general_prompts).map(
              (v) =>
                v.pod.toLowerCase() !== "aivolvex" && all_pod_list.push(v.pod)
            );
          } else {
            var podlist = JSON.parse(data.aivolvex_general_prompts).map(
              (v) =>
                v.pod.toLowerCase() === "aivolvex" && all_pod_list.push(v.pod)
            );
          }
          const pod = new Set([...all_pod_list]);
          setRecords(JSON.parse(data.aivolvex_general_prompts));
          const item_list = [...pod].map((pod_name, index) => {
            return {
              key: index,
              label: pod_name,
              children: (
                <AvatorList
                  records={JSON.parse(data.aivolvex_general_prompts)}
                  pod={pod_name}
                />
              ),
              className: "plat-dashboard-link",
              onClick: () => handleBasicClick(index),
              active: basicActive === index,
            };
          });
          setPodList(item_list);
          // setRecords(JSON.parse(data.aivolvex_general_prompts));
        } else {
          setPodList([]);
          setRecords([]);
          // setRecords([]);
        }
      })
      .catch((err) => {
        console.log(err);
        // message.error(err);
      });
  }, [props]);

  // useEffect;
  const items = podlist;
  // const items = [
  //   {
  //     key: "1",
  //     label: `IT Operations`,
  //     children: <AvatorList />,
  //     className: "plat-dashboard-link",
  //     onClick: () => handleBasicClick("1"),
  //     active: basicActive === "1",
  //   },
  //   {
  //     key: "2",
  //     label: `Customer Operations`,
  //     children: <AvatorList2 />,
  //     className: "plat-dashboard-link",
  //     onClick: () => handleBasicClick("2"),
  //     active: basicActive === "2",
  //   },
  //   {
  //     key: "3",
  //     label: `Legal Operation`,
  //     children: <AvatorList3 />,
  //     className: "plat-dashboard-link",
  //     onClick: () => handleBasicClick("3"),
  //     active: basicActive === "3",
  //   },
  //   {
  //     key: "4",
  //     label: `Procurement`,
  //     children: <AvatorList />,
  //     className: "plat-dashboard-link",
  //     onClick: () => handleBasicClick("4"),
  //     active: basicActive === "4",
  //   },
  // ];

  return (
    <>
      <NavBar />
      <div className="body-bg">
        <Row>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={5}
            xl={5}
            className="window-margin-right  body-margin-top body-margin-bottom-mobile"
          >
            <Sidemenu pagetype={props.pagetype} />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={18}
            xl={18}
            className="body-margin-top body-margin-top-mobile"
          >
            <Row className="mt-2">
              <Col span={24}>
                <h3 className="white-color aivolvex-font aivolvex-page-title">
                  {" "}
                  <img
                    src={aivolvex}
                    alt="logo"
                    className="library-head-logo"
                  />{" "}
                  {window.location.pathname === "/avatarhub/enterprise" ? (
                    <>Avatar Hub </>
                  ) : (
                    <>AIVolveX Hub </>
                  )}
                </h3>
              </Col>
            </Row>
            {!loader && (
              <Row className="mt-2">
                <Col span={24}>
                  {/* <Tabs
                  className="cams-tabs aivolvex-font"
                  items={items}
                  onChange={handleBasicClick}
                /> */}
                  <Tabs
                    className="cams-tabs aivolvex-font"
                    activeKey={basicActive}
                    onChange={handleBasicClick}
                  >
                    {items.map((item) => (
                      <Tabs.TabPane tab={item.label} key={item.key}>
                        {item.children}
                      </Tabs.TabPane>
                    ))}
                  </Tabs>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default memo(Library);
