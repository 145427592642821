import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  ApolloProvider,
  gql,
  concat,
} from "@apollo/client";

const httpLink = new HttpLink({
  //pro
  // uri: "https://6bllkbe5qjf2lpnnbmaemeu73i.appsync-api.ap-south-1.amazonaws.com/graphql",
  //dev
  uri: "https://sftr5jnsffdvvcxmfy2dhtmyv4.appsync-api.ap-south-1.amazonaws.com/graphql",
  // uri: "https://kpfcmpncnzhazg2muvwthqiike.appsync-api.ap-south-1.amazonaws.com/graphql",
});

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      //pro
      // "x-api-key": "da2-ltueu7cyrnbqbg3hppmffl63sa",
      //dev
      "x-api-key": "da2-y4ydrhjm4vcmzcfnb7t6rwbqmu",
      // "x-api-key": "da2-vv7svqhqmvcidpxfpguqrppqba",
      Authorization: localStorage.getItem("_token"),
    },
  }));

  return forward(operation);
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, httpLink),
});

export default client;
