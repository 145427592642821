import React from "react";
import { Link } from "react-router-dom";
import { Carousel } from "antd";
import SignInCarousel1 from "../banners/SignInCarousel1";
import SignInCarousel2 from "../banners/SignInCarousel2";
import SignInCarousel3 from "../banners/SignInCarousel3";
import "../Login/login.scss";

const LoginBanner = () => {
  const contentStyle = {
    margin: 0,
    height: "100vh",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
  };

  const onChange = (currentSlide) => {
    // console.log(currentSlide);
  };

  return (
    <>
      <div className="nav-logo">
        {/* <Link to="/">
          AIVolveX
          <img src={""} alt="nav-logo" className="navlogo signinLogoBanner" />
        </Link> */}
      </div>
      <Carousel afterChange={onChange} autoplay autoplaySpeed={3000}>
        {/* <Carousel afterChange={onChange}> */}
        <div>
          <SignInCarousel1 />
        </div>
        <div>
          <SignInCarousel2 />
        </div>
        <div>
          <SignInCarousel3 />
        </div>
      </Carousel>
    </>
  );
};

export default LoginBanner;
