import React, { memo, useEffect, useState } from "react";
import { Col, Row, Avatar, Card } from "antd";
import avator_img from "../../assets/demo_avatar.jpg";
import { Icon } from "@iconify/react";
import FeedbackModel from "./FeedbackModel";
import parse from "html-react-parser";
import { useNavigate, useParams } from "react-router-dom";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ChatListScroll from "./ChatListScroll";
import MarkdownToJSX from "markdown-to-jsx";
import avatar1 from "../../assets/ui/Avatar 1.svg";
import avatar2 from "../../assets/ui/Avatar 2.svg";
import avatar3 from "../../assets/ui/Avatar 3.svg";
import avatar4 from "../../assets/ui/Avatar 4.svg";
import avatar5 from "../../assets/ui/Avatar 5.svg";
import ChatCopy from "./ChatCopy";
import WordDownload from "./WordDownload";
import SocialMediaModel from "./SocialMediaModel";
import { Tooltip, Button } from "antd";
import ChatPagination from "./ChatPagination";
import SourceModel from "./SourceModel";
import ceoimg from "../../assets/ceo.png";
import aivolvex from "../../assets/ui/X Icon SVG.svg";

const ChartList = (props) => {
  console.log(props.avatardetails.avatar_name);
  // console.log(props);
  const [open, setOpen] = useState(false);
  const [bodycontent, setBodycontent] = useState("");
  const [socialopen, setSocialOpen] = useState(false);
  const [socialmediacontent, setSocialMediaContent] = useState("");
  const [sourcemodel, setSourceModel] = useState(false);
  const [sourcerecords, setSourceRecords] = useState([]);
  const parse = require("html-react-parser");
  const [pagination, setPagination] = useState(1);
  const tag_id = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.tags")
  );
  const showModal = () => {
    setOpen(true);
  };
  const [avatarimg, setAvatarImg] = useState(avatar1);
  const { id } = useParams();

  const [currentPage, setCurrentPage] = useState(1);

  const containerRef = React.useRef();

  const handleScroll = () => {
    const container = containerRef.current;
    if (
      container.scrollHeight - container.scrollTop ===
      container.clientHeight
    ) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    container?.addEventListener("scroll", handleScroll);
    return () => container?.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    // console.log(id);
    if (id === "365" || id === "469" || id === "465") {
      setAvatarImg(avatar1);
    } else if (id === "496" || id === "67" || id === "1") {
      setAvatarImg(avatar2);
    } else if (id === "466" || id === "468") {
      setAvatarImg(avatar3);
    } else if (id === "464" || id === "467") {
      setAvatarImg(avatar4);
    } else {
      setAvatarImg(avatar5);
    }
  }, [props]);
  // const handleDownloadPDF = async (divName) => {
  //   const targetDivs = document.querySelectorAll(`[name="${divName}"]`);
  //   console.log(targetDivs)
  //   if (targetDivs.length === 0) {
  //     console.error(`No div found with the name '${divName}'`);
  //     return;
  //   }

  //   const targetDiv = targetDivs[0];
  //   console.log(targetDiv)
  //   const pdf = new jsPDF();
  //   console.log(pdf)

  //   try {
  //     // Use html2canvas to capture the content of the <pre> element
  //     const canvas = await html2canvas(targetDiv);

  //     // Convert the canvas to a data URL
  //     const imgData = canvas.toDataURL("image/png");

  //     // Add the image to the PDF
  //     // pdf.addImage(imgData, "PNG", 10, 10, 190, 0);
  //     pdf.addImage(imgData, "PNG", 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());

  //     // Save the PDF
  //     pdf.save("aivolvex.pdf");
  //   } catch (error) {
  //     console.error("Error generating PDF:", error);
  //   }
  // };
  const handleDownloadPDF = (divName) => {
    const targetDiv = document.querySelector(`[name="${divName}"]`);
    const pdf = new jsPDF({
      orientation: "portrait", // Set the orientation to portrait (vertical)
    });

    // Get the content of the <pre> element as text
    const contentText = targetDiv.textContent;

    const pageHeight = pdf.internal.pageSize.getHeight() - 20; // Adjust as needed

    let yOffset = 10;
    let textIndex = 0;

    while (textIndex < contentText.length) {
      // Calculate the remaining height on the current page
      const remainingHeight = pageHeight - yOffset;

      // Determine how much text can fit on the current page
      const textToFit = contentText.substring(textIndex, textIndex + 500); // Adjust as needed

      // Add the text to the current page
      pdf.text(15, yOffset, textToFit);

      // Advance the yOffset
      yOffset += 10;

      // Move to the next page if necessary
      if (yOffset > remainingHeight) {
        pdf.addPage();
        yOffset = 10; // Reset yOffset for the new page
      }

      // Update the textIndex
      textIndex += textToFit.length;
    }

    pdf.save("aivolvex.pdf");
  };

  // const handleDownloadPDF = (divName) => {
  //   const targetDiv = document.querySelector(`[name="${divName}"]`);
  //   const pdf = new jsPDF();

  //   // Get the content of the <pre> element as HTML
  //   const contentHTML = targetDiv.innerHTML;

  //   // Add the HTML content to the PDF
  //   pdf.html(contentHTML, {
  //     callback: () => {
  //       pdf.save('aivolvex.pdf');
  //     },
  //     x: 10,
  //     y: 10,
  //   });
  // };

  // const handleDownloadPDF = async (divName) => {
  //   console.log(divName)
  //   // const divName = "content-to-convert";
  //   const targetDivs = document.querySelectorAll(`[name="${divName}"]`);
  //   //NodeList [pre.responce-pre.aivolvex-font]
  //   console.log(targetDivs)
  //   if (targetDivs.length === 0) {
  //     console.error(`No div found with the name '${divName}'`);
  //     return;
  //   }

  //   const targetDiv = targetDivs[0];
  //   const pdf = new jsPDF();
  //   try {
  //     const canvas = await html2canvas(targetDiv);
  //     const imgData = canvas.toDataURL("image/png");
  //     pdf.addImage(imgData, "PNG", 10, 10, 190, 0);
  //     pdf.save("aivolvex.pdf");
  //   } catch (error) {
  //     console.error("Error generating PDF:", error);
  //   }
  // };

  function isMarkdownTableFormat(text) {
    const markdownTableRegex = /^\s*\|.*\|\s*$/m;
    return markdownTableRegex.test(text);
  }
  console.log(props)
  return (
    <>
      {/* <SocialMediaModel
        open={socialopen}
        setOpen={setSocialOpen}
        socialmediacontent={socialmediacontent}
      /> */}
      {sourcemodel && (
        <SourceModel
          open={sourcemodel}
          setOpen={setSourceModel}
          sourcerecords={sourcerecords}
        />
      )}

      <FeedbackModel open={open} setOpen={setOpen} avatarid={id} />
      <Row ref={containerRef}>
        <ChatListScroll>
          {props.messages.map((data, index) => (
            <>
              {data.user ? (
                <>
                  <Col span={24} className="mt-4">
                    <div className="chat-message">
                      <div className="chat-avator-img">
                        {/* <Avatar className="chat-avatar-icon-color">D</Avatar> */}
                        {tag_id === "all" ? (
                          <>
                            <img
                              src={ceoimg}
                              alt=""
                              className="chat-avator-img"
                            />
                          </>
                        ) : (
                          <>
                            <Icon
                              icon="healthicons:ui-user-profile"
                              className="chat-avator-img"
                              style={{ color: "#fff" }}
                            />
                          </>
                        )}
                      </div>
                      <div className="chat-send-window">
                        <pre className="responce-pre aivolvex-font">
                          <div
                            dangerouslySetInnerHTML={{ __html: data.text }}
                          />
                        </pre>
                        <div className="time-question-body">
                          <div></div>
                          <div>
                            <label className="footer-chat-time">
                              {data.date}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </>
              ) : (
                <>
                  {data.type === "loader" ? (
                    <>
                      <Col span={24} className="mt-4">
                        <div className="chat-message chat-messge-ans">
                          <div className="chat-avator-img">
                            <img
                              src={props.avatardetails.avatar_image}
                              alt=""
                              className="chat-avator-img"
                            />
                          </div>
                          <div className="chat-ans-window">
                            <div className="text-center">
                              <div class="lds-ellipsis">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </>
                  ) : data.type === "kendra_message" ? (
                    <>
                      <Col span={24} className="mt-4">
                        <div className="chat-message">
                          <div className="chat-avator-img">
                            <img
                              src={props.avatardetails.avatar_image}
                              alt=""
                              className="chat-avator-img"
                            />
                          </div>
                          <div className="chat-ans-window">
                            <div className="feedback-body mb-1">
                              {/* <Icon
                                icon="vscode-icons:file-type-outlook"
                                className="feedback-icon"
                                onClick={() => {
                                  const owaComposeURL =
                                    "https://outlook.office365.com/owa/?path=/mail/action/compose";
                                  const subject =
                                    props.avatardetails.avatar_name;
                                  const body = data.text;
                                  const encodedSubject =
                                    encodeURIComponent(subject);
                                  const encodedBody = encodeURIComponent(body);

                                  const popupWidth = 600;
                                  const popupHeight = 400;

                                  const left =
                                    window.innerWidth / 2 - popupWidth / 2;
                                  const top =
                                    window.innerHeight / 2 - popupHeight / 2;

                                  const finalURL = `${owaComposeURL}&subject=${encodedSubject}&body=${encodedBody}`;

                                  const popupWindow = window.open(
                                    finalURL,
                                    "_blank",
                                    `width=${popupWidth},height=${popupHeight},left=${left},top=${top}`
                                  );
                                  if (popupWindow) {
                                    popupWindow.focus();
                                  }
                                }}
                              /> */}
                              <Icon
                                icon="simple-icons:microsoftoutlook"
                                className="outlook-icon"
                              />
                              <Icon
                                icon="vscode-icons:file-type-word"
                                className="feedback-icon"
                              />
                              <Icon
                                icon="vscode-icons:file-type-word"
                                className="feedback-icon"
                              />
                              <Icon
                                icon="vscode-icons:file-type-pdf2"
                                className="feedback-icon"
                                onClick={() => {
                                  handleDownloadPDF(
                                    `content-to-convert-${index}`
                                  );
                                }}
                              />
                              <ChatCopy text={data.text} />
                              {/* <WordDownload /> */}
                              <div>
                                {data.message_list.length > 0 && (
                                  <>
                                    {pagination != 1 && (
                                      <Icon
                                        icon="grommet-icons:previous"
                                        className="paginaction-icon"
                                        onClick={() => {
                                          setPagination(pagination - 1);
                                        }}
                                      />
                                    )}{" "}
                                    {pagination}/{data.message_list.length}{" "}
                                    {pagination <=
                                      data.message_list.length - 1 && (
                                        <Icon
                                          icon="grommet-icons:next"
                                          className="paginaction-icon"
                                          onClick={() => {
                                            setPagination(pagination + 1);
                                          }}
                                        />
                                      )}
                                  </>
                                )}
                              </div>
                            </div>
                            <ChatPagination
                              allmessage={data.message_list}
                              pdf={data.pdf}
                              pdfname={data.pdfname}
                              indexvalue={index}
                              isMarkdownTableFormat={isMarkdownTableFormat}
                              pagination={pagination}
                              setPagination={setPagination}
                            />

                            <div className="feedback-body d-flex justify-content-between">
                              <div>
                                <label className="footer-chat-time">
                                  {data.date}
                                </label>
                              </div>
                              <div>
                                {/* <Tooltip
                                  title={
                                    <SocialMediaModel
                                      socialmediacontent={data.text}
                                    />
                                  }
                                  trigger="click"
                                  // color="#fff"
                                >
                                  <Icon
                                    icon="icon-park-solid:share-one"
                                    className="feedback-icon"
                                    onClick={() => {
                                      setSocialMediaContent(data.text);
                                      // setSocialOpen(true);
                                    }}
                                  />
                                </Tooltip> */}

                                <Icon
                                  icon="iconamoon:like-thin"
                                  className="feedback-icon"
                                  onClick={() => showModal()}
                                />
                                {/* <Icon
                                  icon="iconamoon:dislike-thin"
                                  className="feedback-icon"
                                  onClick={() => showModal()}
                                /> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col span={24} className="mt-4">
                        <div className="chat-message chat-messge-ans">
                          <div className="chat-avator-img">
                            <img
                              src={props.avatardetails.avatar_image}
                              alt=""
                              className="chat-avator-img"
                            />
                            <div className="chat-ans-box-token">
                              {/* <span className="d-flex justify-content-between">
                                <span>10</span>{" "}
                                <img
                                  src={aivolvex}
                                  alt="logo"
                                  className="message-taken-logo"
                                />
                              </span> */}
                            </div>
                          </div>
                          <div className="chat-ans-window">
                            <pre
                              className="responce-pre aivolvex-font"
                              name={`content-to-convert-${index}`}
                            >
                              {/* {setBodycontent(data.text)} */}
                              {/* {data.text} */}
                              {/* {parse(data.text)} */}

                              {/* <MarkdownToJSX>{data.text}</MarkdownToJSX> */}

                              {isMarkdownTableFormat(data.text) ? (
                                <MarkdownToJSX>{data.text}</MarkdownToJSX>
                              ) : (
                                <>{parse(data.text)}</>
                              )}
                              {data.source && (
                                <>
                                  <div className="d-flex justify-content-end">
                                    <p
                                      className="cursor-pointer app-secondary-color"
                                      onClick={() => {
                                        setSourceModel(true);
                                        setSourceRecords(data.message_list);
                                      }}
                                    >
                                      View Source
                                    </p>
                                  </div>
                                </>
                              )}
                              {data.pdf && (
                                <>
                                  <div className="d-flex justify-content-end">
                                    <div>
                                      <p>Page : {data.pagenumber} / </p>
                                    </div>
                                    <div>
                                      <p
                                        className="bot-kendra-pdf"
                                        onClick={() => {
                                          window.open(data.pdf, "_blank");
                                        }}
                                      >
                                        <Icon
                                          icon="vscode-icons:file-type-pdf2"
                                          className="feedback-icon"
                                        />{" "}
                                        {data.pdfname}{" "}
                                      </p>
                                    </div>
                                  </div>
                                </>
                              )}
                            </pre>
                            <div className="d-flex justify-content-between">
                              <div>
                                <label className="footer-chat-time">
                                  {data.date}
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="feedback-body mb-1">
                            <div>
                              {/* <Icon
                                icon="vscode-icons:file-type-word"
                                className="feedback-icon"
                              /> */}
                              <Icon
                                icon="vscode-icons:file-type-pdf2"
                                className="feedback-icon"
                                onClick={() => {
                                  handleDownloadPDF(
                                    `content-to-convert-${index}`
                                  );
                                }}
                              />
                              <ChatCopy text={data.text} />
                              {console.log(data.text)}
                            </div>
                            <div>
                              <Icon
                                icon="vscode-icons:file-type-outlook"
                                className="feedback-icon"
                                onClick={() => {
                                  const owaComposeURL =
                                    "https://outlook.office365.com/owa/?path=/mail/action/compose";
                                  const subject =
                                    props.avatardetails.avatar_name;
                                  const body = data.text;


                                  const encodedSubject =
                                    encodeURIComponent(subject);
                                  const encodedBody = encodeURIComponent(body);

                                  const popupWidth = 600;
                                  const popupHeight = 400;

                                  const left =
                                    window.innerWidth / 2 - popupWidth / 2;
                                  const top =
                                    window.innerHeight / 2 - popupHeight / 2;

                                  const finalURL = `${owaComposeURL}&subject=${encodedSubject}&body=${encodedBody}`;

                                  const popupWindow = window.open(
                                    finalURL,
                                    "_blank",
                                    `width=${popupWidth},height=${popupHeight},left=${left},top=${top}`
                                  );
                                  if (popupWindow) {
                                    popupWindow.focus();
                                  }
                                }}
                              />
                              {/* <Tooltip
                                title={
                                  <SocialMediaModel
                                    socialmediacontent={data.text}
                                  />
                                }
                                trigger="click"
                                // color="#fff"
                              >
                                <Icon
                                  icon="icon-park-solid:share-one"
                                  className="feedback-icon"
                                  onClick={() => {
                                    setSocialMediaContent(data.text);
                                    // setSocialOpen(true);
                                  }}
                                />
                              </Tooltip> */}

                              <Icon
                                icon="iconamoon:like-thin"
                                className="feedback-icon"
                                onClick={() => showModal()}
                              />
                              {/* <Icon
                                icon="iconamoon:dislike-thin"
                                className="feedback-icon"
                                onClick={() => showModal()}
                              /> */}
                            </div>
                          </div>
                        </div>
                      </Col>
                    </>
                  )}
                </>
              )}
            </>
          ))}
        </ChatListScroll>
      </Row>
    </>
  );
};

export default memo(ChartList);
