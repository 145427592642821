import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import jwtDecode from "jwt-decode";
import { useDispatch } from "react-redux";
import { getCustomerDetails } from "../api/accountAction";
// import { userInfoMethod } from "../slice/userSlice";
import { message, Spin } from "antd";

const AzureAuth = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigateFn = useNavigate();
  const [messagestatus, setmessageStatus] = useState(false);
  const [errormessagestatus, setErrorMessageStatus] = useState(false);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    setLoader(true);
    const urlParams = new URLSearchParams(location.search);
    const hashParams = location.hash.substring(1);
    const result = hashParams.split("&");
    const idToken = result
      .find((param) => param.startsWith("id_token="))
      .split("=")[1];
    const decodedToken = jwtDecode(idToken);
    // console.log("Decoded id_token:", decodedToken);
    // Access the payload data from the decoded token
    const user_id = decodedToken["cognito:username"];
    const exp_time = decodedToken["exp"];
    const email = decodedToken.email;
    dispatch(getCustomerDetails(email))
      .unwrap()
      .then(({ data }) => {
        console.log(JSON.parse(data.aivolvex_general_prompts)[0]);
        const normalencodedString = btoa("azure");
        const encodedEmail = btoa(decodedToken.email);
        const encodedString = btoa(
          JSON.parse(data.aivolvex_general_prompts)[0]
        );
        localStorage.setItem(
          "AIVolveXIdentityServiceProvider.com",
          encodedString
        );
        localStorage.setItem(
          "AIVolveXIdentityServiceProvider.logtype",
          normalencodedString
        );
        localStorage.setItem(
          "AIVolveXIdentityServiceProvider.auth",
          encodedEmail
        );
        // navigateFn("/avatarhub/enterprise");
      })
      .catch((err) => {
        console.log(err);
      });

    // dispatch(loginUser(records))
    //   .unwrap()
    //   .then(({ data }) => {

    //   })
    //   .catch((err) => {
    //     setLoader(false);
    //     setErrorMessageStatus(true);
    //     localStorage.removeItem("_token");
    //     localStorage.removeItem("CognitoIdentityServiceProvider.auth");
    //     console.log(err);
    //   });

    // console.log(records);
  }, [location]);

  // const ssoAccountLogout = async () => {
  //   await msalInstance.logoutPopup();
  // };

  return (
    <>
      <Spin spinning={loader}>
        {messagestatus && (
          <div className="azure-error-message">
            <div className="azure-error-message-centent">
              Account created successfully, but your role was not assigned.
              Please contact our support team for assistance.
            </div>
          </div>
        )}
        {errormessagestatus && (
          <div className="azure-error-message">
            <div className="azure-error-message-centent">
              Oops! Something went wrong. Please contact our support team for
              assistance.
            </div>
          </div>
        )}
      </Spin>
    </>
  );
};

export default AzureAuth;
