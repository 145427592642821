import { Button, Form, Input, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAvatarTagandMembersList,
  shareAvatarConversion,
} from "../api/accountAction";
import { Icon } from "@iconify/react";

const SharedForm = (props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [optionlist, setOptionList] = useState([]);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const companyName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.com")
  );
  const userName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
  );

  useEffect(() => {
    const input = {
      event_type: "avatar_share_title_list",
      company: companyName,
      share_type: props.type,
      user_id: userName,
      avatar_id: props.avatar_id,
    };
    dispatch(getAvatarTagandMembersList(input))
      .unwrap()
      .then(({ data }) => {
        const data_list = JSON.parse(data.aivolvex_general_prompts);
        setOptionList(data_list);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.type]);

  const onFinishRole = (values) => {
    setIsSubmitting(true);
    const input = {
      share_type: props.type,
      user_id: userName,
      user_session: props.sessionid,
      selected_id: values.tag.toString(),
      title: values.title,
      company: companyName,
      avatar_id: props.avatar_id,
    };
    dispatch(shareAvatarConversion(input))
      .unwrap()
      .then(({ data }) => {
        setIsSubmitting(false);
        message.success("Your conversation has been successfully shared");
        form.resetFields();
        props.setOpen(false);
      })
      .catch((err) => {
        setIsSubmitting(false);
        console.log(err);
      });
  };

  return (
    <>
      <Form onFinish={onFinishRole} className="mt-5">
        <Form.Item
          name="title"
          label="Topic"
          className="lable-white custom-width"
          rules={[{ required: true, message: "Please Enter the Topic" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="tag"
          label={props.type === "tag" ? "Tag" : "Member"}
          className="lable-white custom-width"
          rules={[{ required: true, message: "Please Select" }]}
        >
          <Select
            mode="multiple"
            allowClear
            style={{
              width: "100%",
            }}
            placeholder="Please select"
            options={optionlist}
          />
        </Form.Item>
        <Form.Item className="text-right">
          <Button
            htmlType="submit"
            className="plat-login-button"
            loading={isSubmitting}
          >
            Share <Icon icon="icons8:share" />
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default SharedForm;
