import { memo, useRef } from "react";
import { Icon } from "@iconify/react";
import AboutScroll from "./AboutScroll";
import PromptsAboutScroll from "./PromptsAboutScroll";
import aivolvex from "../../assets/ui/X Icon SVG.svg";

const PromptsList = (props) => {
  const contentRef = useRef(null);
  const tag_id = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.tags")
  );
  const handleScroll = (scrollAmount) => {
    if (contentRef.current) {
      console.log(scrollAmount);

      contentRef.current.scrollTop += scrollAmount;
    }
  };
  return (
    <>
      <div className="aivolvex-font">
        {/* <div className="text-center">
          <label className="prompt-tap-title">Prompts Library</label>
        </div> */}
        <div className="side-scroll-container-prompts">
          {props.promptslist.map((data) => {
            return (
              <button
                class="button-primary-questions  btn btn-primary fontSize10"
                role="button"
                onClick={() => {
                  props.getPromptsContent(data);
                }}
              >
                <div className="btn-main-box d-flex justify-content-between">
                  <div className="btn-prompts-content">{data}</div>
                  {tag_id === "all" && (
                    <div className="btn-prompts-token">
                      <span className="d-flex justify-content-between">
                        <span>100</span>{" "}
                        <img
                          src={aivolvex}
                          alt="logo"
                          className="token-footer-logo"
                        />
                      </span>
                    </div>
                  )}
                </div>
              </button>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default memo(PromptsList);
