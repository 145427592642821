import { gql } from "@apollo/client";

const ONBOARD_AVATAR = gql`
  query MyQuery(
    $about: String!
    $avatar_title: String!
    $context: String!
    $pod_category: String!
    $avatar_image: String!
    $avatar_owner: String!
    $version: String!
  ) {
    aivolvex_general_prompts(
      event_type: "new-avatar"
      company: "cmacgm"
      context: $context
      pod_type: "public"
      about_us: $about
      pod: $pod_category
      avatar_name: $avatar_title
      avatar_image: $avatar_image
      avatar_owner: $avatar_owner
      version: $version
    )
  }
`;

const LIST_AVATAR = gql`
  query MyQuery($company_name: String!, $tag_id: String!) {
    aivolvex_general_prompts(
      event_type: "get-avatar-details"
      company: $company_name
      tag_id: $tag_id
    )
  }
`;

const UPDATE_AVATAR = gql`
  query MyQuery(
    $context: String!
    $about_us: String!
    $pod: String!
    $avatar_name: String!
    $avatar_id: String!
    $avatar_image: String!
    $avatar_owner: String!
    $version: String!
  ) {
    aivolvex_general_prompts(
      event_type: "update-context"
      company: "cmacgm"
      context: $context
      pod_type: "public"
      about_us: $about_us
      pod: $pod
      avatar_name: $avatar_name
      avatar_id: $avatar_id
      avatar_image: $avatar_image
      avatar_owner: $avatar_owner
      version: $version
    )
  }
`;

const GET_AVATAR_DETAILS = gql`
  query MyQuery($id: String!) {
    aivolvex_general_prompts(event_type: "pod-context-details", avatar_id: $id)
  }
`;

const GET_START_CONVERSATION_VERTEX_AI = gql`
  query MyQuery(
    $date_time: String!
    $user_session: String!
    $avatar_id: String!
    $chat: String!
    $ai: String!
    $schema: String!
    $user_name: String!
    $managed_by: String!
  ) {
    aivolvex_general_prompts(
      event_type: "chat"
      company: $schema
      user_id: $user_name
      date_time: $date_time
      user_session: $user_session
      avatar_id: $avatar_id
      chat: $chat
      ai: $ai
      managed_by: $managed_by
    )
  }
`;

const GET_START_CONVERSATION = gql`
  query MyQuery(
    $date_time: String!
    $user_session: String!
    $avatar_id: String!
    $chat: String!
    $schema: String!
    $user_name: String!
    $managed_by: String!
  ) {
    open_ai_prompts(
      ai: "openai"
      chat: $chat
      avatar_id: $avatar_id
      company: $schema
      date_time: $date_time
      event_type: "chat"
      managed_by: $managed_by
      user_session: $user_session
      user_id: $user_name
    )
  }
`;

const GET_START_CONVERSATION_CLOUDSHIFU = gql`
  query MyQuery(
    $date_time: String!
    $user_session: String!
    $chat: String!
    $schema: String!
    $user_name: String!
  ) {
    chatbot_chat(
      ai: "vertexai"
      cloud: "aws"
      date_time: $date_time
      user_session: $user_session
      user_name: $user_name
      schema: $schema
      query: $chat
      event_type: "chat"
    )
  }
`;
const LOGIN_EMAIL_CHECK = gql`
  query MyQuery($user_id: String!, $password: String!) {
    aivolvex_general_prompts(
      event_type: "email-id-verification"
      user_id: $user_id
      password: $password
    )
  }
`;

const AZUREADEMAILCHECK = gql`
  query MyQuery($email: String!) {
    company_check(emailid: $email, event_type: "company_check")
  }
`;

const GET_START_CONVERSATION_KENDRA = gql`
  query MyQuery(
    $chat: String!
    $session_id: String!
    $avatar_id: String!
    $customer: String!
    $user_id: String!
  ) {
    query_kendra(
      question: $chat
      user_session: $session_id
      avatar_id: $avatar_id
      customer: $customer
      user_id: $user_id
    )
  }
`;

const GET_START_CONVERSATION_PG_VECTOR = gql`
  query MyQuery(
    $chat: String!
    $session_id: String!
    $avatar_id: String!
    $customer: String!
    $user_id: String!
  ) {
    query_pgvector(
      avatar_id: $avatar_id
      customer: $customer
      user_id: $user_id
      user_session: $session_id
      avatar_type: "pgvector"
      question: $chat
    )
  }
`;

const AVATARFREQUENTLYPROMPTS = gql`
  query MyQuery($id: String!, $user_id: String!) {
    aivolvex_general_prompts(
      event_type: "get-frequently-used-prompts"
      user_id: $user_id
      avatar_id: $id
    )
  }
`;

const AVATARHISTORYTITLE = gql`
  query MyQuery($id: String!, $user_id: String!) {
    aivolvex_general_prompts(
      event_type: "get-title-list"
      user_id: $user_id
      avatar_id: $id
    )
  }
`;

const AVATARCHATHISTORY = gql`
  query MyQuery($conversationid: String!) {
    aivolvex_general_prompts(
      event_type: "get-history"
      user_session: $conversationid
    )
  }
`;

const CUSTOMERDETAILS = gql`
  query MyQuery($email: String!) {
    aivolvex_general_prompts(event_type: "get-customer", user_id: $email)
  }
`;

const GETTOKENREMAINING = gql`
  query MyQuery($company: String!) {
    aivolvex_general_prompts(
      event_type: "get-remaining_credits"
      company: $company
    )
  }
`;

const KENDRA_OPENAI = gql`
  query MyQuery(
    $prompts: String!
    $session_id: String!
    $avatar_id: String!
    $customer: String!
    $user_id: String!
    $question: String!
  ) {
    openai_response(
      prompt: $prompts
      user_session: $session_id
      avatar_id: $avatar_id
      customer: $customer
      user_id: $user_id
      question: $question
    )
  }
`;

const GET_START_CONVERSATION_GENERAL_CHAT = gql`
  query MyQuery(
    $chat: String!
    $session_id: String!
    $avatar_id: String!
    $customer: String!
    $user_id: String!
    $date_time: String!
    $managed_by: String!
  ) {
    query_titan(
      avatar_id: $avatar_id
      avatar_type: "general"
      customer: $customer
      question: $chat
      user_id: $user_id
      user_session: $session_id
      managed_by: $managed_by
      event_type: "chat"
      date_time: $date_time
    )
  }
`;

const AVATAR_TAG_MEMBERS_LIST = gql`
  query MyQuery(
    $event_type: String!
    $company: String!
    $share_type: String!
    $user_id: String!
    $avatar_id: String!
  ) {
    aivolvex_general_prompts(
      event_type: $event_type
      company: $company
      user_id: $user_id
      share_type: $share_type
      avatar_id: $avatar_id
    )
  }
`;

const SHARE_NEW_AVATRA = gql`
  query MyQuery(
    $share_type: String!
    $user_id: String!
    $user_session: String!
    $selected_id: String!
    $title: String!
    $company: String!
    $avatar_id: String!
  ) {
    aivolvex_general_prompts(
      event_type: "insert-share-conversation"
      share_type: $share_type
      user_id: $user_id
      user_session: $user_session
      selected_id: $selected_id
      company: $company
      avatar_id: $avatar_id
      title: $title
    )
  }
`;

const INSERT_NEW_FEEDBACK = gql`
  query MyQuery(
    $rating: String!
    $feedback: String!
    $avatar_id: String!
    $user_id: String!
    $question_id: String!
    $company: String!
  ) {
    aivolvex_general_prompts(
      event_type: "insert_feedback"
      avatar_id: $avatar_id
      user_id: $user_id
      question_id: $question_id
      rating: $rating
      feedback: $feedback
      company: $company
    )
  }
`;
// const HISTORY_EDIT = gql`
//   query MyQuery(
//     $question_id: String!
//     $company: String!
//   ) {
//     aivolvex_general_prompts(
      
//     )
//   }
// `;
// const HISTORY_DELETE = gql`
//   query MyQuery(
//     $question_id: String!
//     $company: String!
//   ) {
//     aivolvex_general_prompts(
      
//     )
//   }
// `;

export {
  ONBOARD_AVATAR,
  LIST_AVATAR,
  GET_AVATAR_DETAILS,
  GET_START_CONVERSATION,
  UPDATE_AVATAR,
  LOGIN_EMAIL_CHECK,
  GET_START_CONVERSATION_CLOUDSHIFU,
  AZUREADEMAILCHECK,
  GET_START_CONVERSATION_KENDRA,
  AVATARFREQUENTLYPROMPTS,
  AVATARHISTORYTITLE,
  AVATARCHATHISTORY,
  CUSTOMERDETAILS,
  GETTOKENREMAINING,
  KENDRA_OPENAI,
  GET_START_CONVERSATION_PG_VECTOR,
  GET_START_CONVERSATION_GENERAL_CHAT,
  AVATAR_TAG_MEMBERS_LIST,
  SHARE_NEW_AVATRA,
  INSERT_NEW_FEEDBACK,
  // HISTORY_EDIT,
  // HISTORY_DELETE,
};
