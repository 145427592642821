import { memo } from "react";
import LoginBanner from "./LoginBanner";
import "../banners/SignInBanner.scss";
import "./login.scss";
import SignIn from "./SignIn";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useState } from "react";
import SignUp from "./SignUp";
const Login = () => {
  const [showSignUp, setShowSignUp] = useState(false);
  const [showSignIn, setShowSignIn] = useState(true);

  const handleSignUpClick = (e) => {
    if (showSignIn) {
      setShowSignUp(true);
      setShowSignIn(false);
      localStorage.setItem("signUpShow", "signUp");
    } else {
      setShowSignUp(false);
      setShowSignIn(true);
      localStorage.setItem("signUpShow", "");
    }
  };

  return (
    <>
      <div className="main-container d-flex h-screen">
        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 signin-banner singinbanner">
          <LoginBanner />
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex align-items-center">
          <div className="container signincontainer">
            <GoogleOAuthProvider
              clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
            >
              {showSignIn && <SignIn handleSignUpClick={handleSignUpClick} />}

              {/* {showSignUp && <SignUp handleSignUpClick={handleSignUpClick} />} */}
            </GoogleOAuthProvider>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(Login);
