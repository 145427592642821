import React from "react";
import NavBar from "../Header/NavBar";
import { Col, Row, Space, Table } from "antd";
import Sidemenu from "../Sidemenu/Sidemenu";
import { Icon } from "@iconify/react";
import Input from "rc-input";
import { Button } from "react-bootstrap";
import { SearchOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useRef } from "react";
import Highlighter from "react-highlight-words";

function History() {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: "Pod",
      dataIndex: "pod",
      key: "pod",
      width: "15%",
      // ...getColumnSearchProps("pod"),
    },
    {
      title: "Avatar",
      dataIndex: "avatar",
      key: "avatar",
      width: "15%",
      // ...getColumnSearchProps("avatar"),
    },
    {
      title: "User",
      dataIndex: "userId",
      key: "userId",
      width: "15%",
      // ...getColumnSearchProps("userId"),
      // sorter: (a, b) => a.address.length - b.address.length,
      // sortDirections: ['descend', 'ascend'],
    },
    {
      title: "Title",
      dataIndex: "prompt",
      key: "Prompt",
      // ...getColumnSearchProps("prompt"),
      // sorter: (a, b) => a.address.length - b.address.length,
      // sortDirections: ['descend', 'ascend'],
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: "15%",
      align: "center",
      render: (text) => (
        <Space size="middle">
          <a>
            <Icon icon="gg:link" style={{ fontSize: "25px", color: "#fff" }} />
          </a>
          <a>
            <Icon
              icon="ant-design:file-pdf-outlined"
              style={{ fontSize: "25px", color: "#ff2c2c" }}
            />
          </a>
        </Space>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      pod: "Customer Operation",
      avatar: "Customer Enquiry",
      tokenCount: "20",
      userId: "xyz@example.com",
      prompt:
        "What is the estimated project duration for MYSQL to MSSQL Migration?",
    },
    {
      key: "2",
      pod: "Pre Sales Operation	",
      avatar: "SOW Generator",
      tokenCount: "70",
      userId: "abc@example.com",
      prompt: "Generate me an SOW for CMACGM App Development",
    },
    {
      key: "3",
      pod: "IT Operations",
      avatar: "CloudShifu",
      tokenCount: "15",
      userId: "john.doe@example.com",
      prompt: "Get me the cost for the current month",
    },
    {
      key: "4",
      pod: "IT Operations",
      avatar: "Research Analyst",
      tokenCount: "20",
      userId: "jack@example.com",
      prompt: "Latest updates in AWS RDS Service",
    },
    {
      key: "4",
      pod: "IT Operations",
      avatar: "Research Analyst",
      tokenCount: "20",
      userId: "jack@example.com",
      prompt: "Latest updates in AWS RDS Service",
    },
  ];

  //     const dataSource = [
  //         {
  //           key: '1',
  //           pod: 'Pod 1',
  //           avatar: 'Avatar 1',
  //           prompts: 'Prompts 1',
  //         },
  //         {
  //           key: '2',
  //           pod: 'Pod 2',
  //           avatar: 'Avatar 2',
  //           prompts: 'Prompts 2',
  //         },
  //         {
  //           key: '2',
  //           pod: 'Pod 2',
  //           avatar: 'Avatar 2',
  //           prompts: 'Prompts 2',
  //         },{
  //           key: '2',
  //           pod: 'Pod 2',
  //           avatar: 'Avatar 2',
  //           prompts: 'Prompts 2',
  //         },{
  //           key: '2',
  //           pod: 'Pod 2',
  //           avatar: 'Avatar 2',
  //           prompts: 'Prompts 2',
  //         },
  //         // Add more data rows as needed
  //       ];

  //       const columns = [
  //         {
  //           title: 'Pod',
  //           dataIndex: 'pod',
  //           key: 'pod',
  //         },
  //         {
  //           title: 'Avatar',
  //           dataIndex: 'avatar',
  //           key: 'avatar',
  //         },
  //         {
  //           title: 'Prompts',
  //           dataIndex: 'prompts',
  //           key: 'prompts',
  //         },
  //         {
  //           title: 'Actions',
  //           dataIndex: 'actions',
  //           key: 'actions',
  //           render: (text) => (
  //             <Space size="middle">
  //               {/* <a>Invite {record.name}</a> */}
  //               <a><Icon icon="gg:link" style={{ fontSize: '20px', color: '#1890ff' }}/>
  //  </a>
  //             </Space>
  //           ),

  //         },
  //       ];

  const paginationConfig = {
    pageSize: 4, // Set the page size (limit) here
  };
  return (
    <>
      <NavBar />
      <div className="body-bg">
        <Row>
          <Col span={5} className="window-margin-right  body-margin-top">
            <Sidemenu />
          </Col>
          <Col span={18} className="body-margin-top">
            <Row className="mt-4">
              <Col span={24} className="d-flex justify-content-between">
                <h4 className="white-color">History</h4>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Table
                  className="mt-3 ms-2 costimize-bootstrap-table"
                  dataSource={data}
                  columns={columns}
                  pagination={paginationConfig}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default History;
