import "./App.css";
import React, { useEffect } from "react";
import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import Dashboard from "./components/dashboard/Dashboard";
import Login from "./components/Login/Login";
import Library from "./components/Library/Library";
import ChartMain from "./components/ChartWindow/ChartMain";
import History from "./components/History/History";
import OnboardAvatar from "./components/Avatar/OnboardAvatar";
import Favorites from "./components/Favorites/Favorites";
import RecentPrompts from "./components/Recent-Prompts/RecentPrompts";
import AzureAuth from "./components/Azure_auth/AzureAuth";
import Launch from "./components/Launch/Launch";
import RoleHome from "./components/Role/RoleHome";
import RoleMembersConfig from "./components/Role/RoleMembersConfig";
import RoleAvatarConfig from "./components/Role/RoleAvatarConfig";
import GPT from "./components/gpt/GPT";
import SourcePDFView from "./components/ChartWindow/SourcePDFView";

//components path
// const Login = React.lazy(() => import("./components/Login/Login"));
// const Library = React.lazy(() => import("./components/Library/Library"));
// const ChartMain = React.lazy(() =>
//   import("./components/ChartWindow/ChartMain")
// );

const router = [
  // {
  //   path: "/",
  //   element: <Login />,
  // },
  // {
  //   path: "/signin",
  //   element: <Login />,
  // },
  // {
  //   path: "/launch",
  //   element: <Launch />,
  // },
  {
    path: "/avatarhub/enterprise",
    element: <Library pagetype="enterprice" />,
  },
  {
    path: "/avatarhub/general",
    element: <Library pagetype="general" />,
  },
  {
    path: "/chat/:id",
    element: <ChartMain />,
  },
  // {
  //   path: "/chat/:id/vertexai",
  //   element: <ChartMain />,
  // },
  {
    path: "/chat/:id/:conversationId",
    element: <ChartMain />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/history",
    element: <History />,
  },
  {
    path: "/onboard_avatar",
    element: <OnboardAvatar />,
  },
  {
    path: "/favorites",
    element: <Favorites />,
  },
  {
    path: "/recent_prompts",
    element: <RecentPrompts />,
  },
  {
    path: "/azure-auth",
    element: <AzureAuth />,
  },
  {
    path: "/tag",
    element: <RoleHome />,
  },
  {
    path: "/role/members/config",
    element: <RoleMembersConfig />,
  },
  {
    path: "/role/avatar/config",
    element: <RoleAvatarConfig />,
  },
  {
    path: "/gpt",
    element: <GPT />,
  },
  {
    path: "/pdf/:url",
    element: <SourcePDFView />,
  },
];

function App() {
  const userName = localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
    ? atob(localStorage.getItem("AIVolveXIdentityServiceProvider.auth"))
    : "";

  // useEffect(() => {
  //   console.log("username");
  //   console.log(userName);
  //   console.log(window.location.pathname);
  // }, [window.location.pathname]);

  return (
    <div className="App">
      <BrowserRouter>
        {userName ? (
          <>
            <Routes>
              {router.map((val) => (
                <Route path={val.path} key={val.path} element={val.element} />
              ))}
              <Route
                path="*"
                element={<Navigate replace to="/avatarhub/enterprise" />}
              />
            </Routes>
          </>
        ) : (
          <>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/azure-auth" element={<AzureAuth />} />
              <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
          </>
        )}
        {/* <Routes>
          {router.map((val) => (
            <Route path={val.path} key={val.path} element={val.element} />
          ))}
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes> */}
      </BrowserRouter>
    </div>
  );
}

export default App;
