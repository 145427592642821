// // import { combineReducers } from '@reduxjs/toolkit';
// // import lastWordReducer from './slice'; 

// // const rootReducer = combineReducers({
// //   lastWord: lastWordReducer,
  
// // });

// // export default rootReducer;
// // rootReducer.js

// import { combineReducers } from '@reduxjs/toolkit';
// import {lastWordReducer} from './slice'; 
// import {sessionReducer } from './slice'; 
// import lastWordSliceReducer from './slice';


// const rootReducer = combineReducers({
//   lastWord: lastWordReducer,
//   session: lastWordSliceReducer.sessionReducer,
  

//   // ... other reducers if you have them ...
// });
// const lastWordSliceReducer = {
//   lastWord: lastWordSlice.reducer,
//   sessionReducer,
// };

// export default lastWordSliceReducer;

// export default rootReducer;
import { createSlice } from '@reduxjs/toolkit';

const lastWordSlice = createSlice({
  name: 'lastWord',
  initialState: '',
  reducers: {
    updateLastWord: (state, action) => {
      return action.payload;
    },
  },
});

export const { updateLastWord } = lastWordSlice.actions;
// export default lastWordSlice.reducer;

const sessionSlice = createSlice({
  name: 'session',
  initialState: '',
  reducers: {
    setSessionId: (state, action) => {
      return action.payload;
    },
  },
});

export const { setSessionId } = sessionSlice.actions;
export const sessionReducer = sessionSlice.reducer;

const lastWordSliceReducer = {
  lastWord: lastWordSlice.reducer,
  session: sessionSlice.reducer,
};

export default lastWordSliceReducer;
