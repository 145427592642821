import { memo, useEffect, useState } from "react";
import { Col, Row, Card } from "antd";
import avator_old from "../../assets/demo_avatar.jpg";
import avatar1 from "../../assets/ui/Avatar 1.svg";
import avatar2 from "../../assets/ui/Avatar 2.svg";
import avatar3 from "../../assets/ui/Avatar 3.svg";
import avatar4 from "../../assets/ui/Avatar 4.svg";
import avatar5 from "../../assets/ui/Avatar 5.svg";
import { useNavigate } from "react-router-dom";
import AvatorSingle from "./AvatorSingle";

const AvatorList = (props) => {
  const navigateFn = useNavigate();
  const [avatarrecords, setAvatarRecords] = useState([]);
  const [avatarimg, setAvatarImg] = useState([]);

  useEffect(() => {
    const pod_name = props.pod;
    const records = props.records;
    const newAccList = records.filter((v, index) => v.pod === pod_name);
    setAvatarRecords(newAccList);
    // console.log([avatar1, avatar2, avatar3, avatar4, avatar5]);
    setAvatarImg([avatar1, avatar2, avatar3, avatar4, avatar5]);
    // console.log(avator_img);
  }, [props]);
  const navigateAvatar = (id) => {
    // console.log(id);
    navigateFn("/chat/" + id);
  };
  // console.log(avatarrecords);
  return (
    <>
      <Row>
        <h5 className="white-color aivolvex-font aivolvex-avatar-title">
          Avatars
        </h5>
      </Row>
      <Row className="mt-4" gutter={[40, 40]}>
        {avatarrecords.map((data, index) => (
          <AvatorSingle data={data} navigateAvatar={navigateAvatar} />
        ))}

        {/* <Col span={12}>
          <Card className="avator-list filterResultCard">
            <Row>
              <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                <img src={avator_img} alt="" className="companyLogoImage" />
              </Col>
              <Col xs={24} sm={24} md={20} lg={20} xl={20}>
                <p class="labelStyle-title">Support Mate</p>
                <p class="labelStyle">
                  Introducing Support Mate, your IT Incident Detective! Unravel
                  critical incident details with Support Mate's keen insights,
                  empowering you to troubleshoot and resolve IT issues swiftly,
                  ensuring seamless operations around the clock.
                </p>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                className="text-right"
              >
                <button
                  class="button-primary btn btn-primary fontSize10"
                  role="button"
                >
                  AIVolve
                </button>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={12}>
          <Card className="avator-list filterResultCard">
            <Row>
              <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                <img src={avator_img} alt="" className="companyLogoImage" />
              </Col>
              <Col xs={24} sm={24} md={20} lg={20} xl={20}>
                <p class="labelStyle-title">Pre Sales Operations</p>
                <p class="labelStyle">
                  Meet Pre Sales Operations, your Pre Sales Operations Wizard!
                  Simply share customer info, and watch Pre Sales Operations
                  work its magic, crafting detailed and professional Statements
                  of Work for you in a snap.
                </p>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                className="text-right"
              >
                <button
                  class="button-primary btn btn-primary fontSize10"
                  role="button"
                >
                  AIVolve
                </button>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={12}>
          <Card className="avator-list filterResultCard">
            <Row>
              <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                <img src={avator_img} alt="" className="companyLogoImage" />
              </Col>
              <Col xs={24} sm={24} md={20} lg={20} xl={20}>
                <p class="labelStyle-title">PMO Avatar</p>
                <p class="labelStyle">
                  Introducing ProTrack, your Project Management Guide! Rely on
                  ProTrack to effortlessly outline key action items and ensure
                  seamless follow-up, driving your projects to success with
                  ease.
                </p>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                className="text-right"
              >
                <button
                  class="button-primary btn btn-primary fontSize10"
                  role="button"
                >
                  AIVolve
                </button>
              </Col>
            </Row>
          </Card>
        </Col> */}
      </Row>
    </>
  );
};
export default memo(AvatorList);
