import React, { useState, useRef, useEffect } from "react";
import navLogo from "../../assets/ui/Group 33935.svg";
import "./launch.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { listAvatar, getAvatarDetails } from "../api/accountAction";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateLastWord } from "../Slice/slice";
function Launch() {
  const dispatch = useDispatch();
  const lastWord = useSelector((state) => state.lastWord);
  const [mentions, setMentions] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [queryshow, setqueryshow] = useState(false);
  const [queryqusestions, setqueryquestions] = useState([]);
  const editorRef = useRef(null);
  const navigateFn = useNavigate();
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [value, setValue] = useState();
  const [avatarname, setavatarname] = useState([]);
  const [allowlist, setAllowListStatus] = useState(true);
  const companyName = "cmacgm";
  const [filteredSuggestionsqus, setfilteredSuggestionsqus] = useState([]);
  const [id, setid] = useState();
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [searchquerystatus, setSearchQueryStatus] = useState(false);
  useEffect(() => {
    const encodedString = btoa("cmacgm");
    const encodedEmail = btoa("vsj@1cloudhub.com");
    const normalencodedString = btoa("normal");
    localStorage.setItem("AIVolveXIdentityServiceProvider.com", encodedString);
    localStorage.setItem(
      "AIVolveXIdentityServiceProvider.logtype",
      normalencodedString
    );
    localStorage.setItem("AIVolveXIdentityServiceProvider.auth", encodedEmail);

    const input = {
      company_name: companyName,
    };
    dispatch(listAvatar(input))
      .unwrap()
      .then(({ data }) => {
        if (JSON.parse(data.aivolvex_general_prompts).length > 0) {
          const avatar_details = JSON.parse(data.aivolvex_general_prompts).map(
            (datas) => {
              return {
                avatar_name: datas.avatar_name,
                id: datas.id,
                avatar_image: datas.image,
              };
            }
          );
          const avatarlist = avatar_details.sort((a, b) =>
            a.avatar_name.localeCompare(b.avatar_name)
          );
          console.log(avatarlist);
          setavatarname(avatar_details);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const handleKeyDown = (event) => {
    console.log(event.key);
    if (event.key === "@") {
      if (allowlist) {
        setShowSuggestions(true);
        const suggestionList = avatarname.map((item) => item.avatar_name);
        setSuggestions(avatarname);
        setSearchQueryStatus(true);
      }
    } else if (event.key === "Enter" && searchquerystatus) {
      const selectedSuggestion1 = filteredSuggestions[0];
      if (selectedSuggestion1) {
        handleSuggestionSelect(selectedSuggestion1);
      }
    } else if (event.key === "Enter") {
      const htmlContent = value;
      const tempElement = document.createElement("div");
      tempElement.innerHTML = htmlContent;
      const textContent = tempElement.textContent;
      const content = textContent.replace(selectedSuggestion, " ").trim();
      if (content) {
        dispatch(updateLastWord(content));
        let navigationId = id;
        if (navigationId === undefined) {
          navigationId = 464;
        }
        navigateFn("/chat/" + navigationId);
        setValue();
      }
    } else if (event.key === "Backspace") {
      const inputValue = event.target.value;
      const htmlContent = value;
      const tempElement = document.createElement("div");
      tempElement.innerHTML = htmlContent;
      const textContent = tempElement.textContent;
      if (textContent?.length === 0) {
        setAllowListStatus(true);
        setShowSuggestions(false);
        setqueryshow(false);
      }
      if (mentions[1]) {
        if (mentions[1] !== "") {
          return;
        } else if (mentions[1] === "") {
          mentions.pop();
        }
      } else {
        if (mentions[0]) {
          if (textContent?.length == mentions[0]?.length) {
            setMentions([]);
            setValue("");
            setAllowListStatus(true);
            setShowSuggestions(false);
            setqueryshow(false);
          }
        }
      }
    }
  };
  const handleSuggestionSelect = (suggestion) => {
    const suggestionIdMap = {};
    for (const avatar of avatarname) {
      suggestionIdMap[avatar.avatar_name] = avatar.id;
    }
    const idno = suggestionIdMap[suggestion];
    if (idno !== undefined) {
      setid(idno);
    }
    setSelectedSuggestion(suggestion);
    setSearchQueryStatus(false);
    setqueryshow(true);
    setValue(
      `<span style='color: rgb(219, 42, 172);'>${suggestion}</span> <span>&nbsp;</span>`
    );
    setAllowListStatus(false);
    setSearchQuery("");
    setMentions([...mentions, suggestion]);
    setSuggestions([]);
  };
  const handleInputChange = (event) => {
    setValue(event);
    const htmlContent = value;
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlContent;
    const inputValue = tempElement.textContent;
    const words = inputValue.split(" ");
    const lastWord = words[words.length - 1];
    if (lastWord) {
      const updatedData = words.map((item) =>
        item.replace(selectedSuggestion, "").trim()
      );
      const concatenatedString = updatedData.join(" ");
      const filteredSugg = queryqusestions.filter((suggestion) =>
        suggestion.toLowerCase().includes(concatenatedString.toLowerCase())
      );
      console.log(filteredSugg);
      setfilteredSuggestionsqus(filteredSugg);
    }
    if (lastWord.startsWith("@") && !showSuggestions) {
      console.log("calling");
      setSearchQuery(lastWord.substr(1));
      setShowSuggestions(true);
    } else if (!lastWord.startsWith("@") && showSuggestions) {
      // setShowSuggestions(false);
    } else if (lastWord.startsWith("@") && showSuggestions) {
      setSearchQuery(lastWord.substr(1));
      setShowSuggestions(true);
      // setSearchQueryStatus(true);
    }
  };

  const filteredSuggestions = suggestions.filter(
    (suggestion) =>
      suggestion.avatar_name
        .toLowerCase()
        .indexOf(searchQuery.toLowerCase()) !== -1
  );

  useEffect(() => {
    dispatch(getAvatarDetails(id))
      .unwrap()
      .then(({ data }) => {
        console.log(
          JSON.parse(data.aivolvex_general_prompts)[0].general_prompts
        );
        setqueryquestions(
          JSON.parse(data.aivolvex_general_prompts)[0].general_prompts
        );
        setfilteredSuggestionsqus(
          JSON.parse(data.aivolvex_general_prompts)[0].general_prompts
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);
  const handleQuestionclick = (question) => {
    setqueryshow(false);
    setValue(
      `<span style='color: rgb(219, 42, 172);'>${selectedSuggestion}</span> <span>&nbsp;${question}</span>`
    );
  };

  return (
    <>
      <div className="launch-container">
        <img src={navLogo} alt="nav-logo " className="launch-logo" />
      </div>
      <div className="launch-container-body">
        <div className="launch-container-text">
          <ReactQuill
            value={value}
            ref={editorRef}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder="@ Interact with any Avatar"
            modules={{ toolbar: false }}
            className="launch-qus-search"
          />
        </div>
        <div className="query-list">
          {showSuggestions && filteredSuggestions?.length > 0 && (
            <ul className="suggestions-list-launch">
              {filteredSuggestions.map((suggestion, index) => (
                <li
                  key={index}
                  className="suggestions-list-item"
                  onClick={() => handleSuggestionSelect(suggestion.avatar_name)}
                >
                  {suggestion.avatar_image && (
                    <span>
                      <img
                        src={
                          window.location.href + "" + suggestion.avatar_image
                        }
                        alt="avatar_icon"
                        className="avatar_icon_image"
                      />
                    </span>
                  )}
                  {suggestion.avatar_name}
                </li>
              ))}
            </ul>
          )}
          {queryshow && (
            <ul className="suggestions-list-launchquery">
              {filteredSuggestionsqus.map((question, index) => (
                <li
                  key={index}
                  className="suggestions-list-item-qus"
                  onClick={() => handleQuestionclick(question)}
                >
                  {question}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </>
  );
}
export default Launch;
