import { memo, useEffect, useState } from "react";
import NavBar from "../Header/NavBar";
import Sidemenu from "../Sidemenu/Sidemenu";
import { UploadOutlined } from "@ant-design/icons";
import { Col, Row, Table, Upload } from "antd";
import {
  Button,
  DatePicker,
  Drawer,
  Form,
  Input,
  Select,
  Space,
  message,
} from "antd";
import { Icon } from "@iconify/react";
import { useDispatch } from "react-redux";
import {
  getAvatarDetails,
  listAvatar,
  onboardAvatar,
  updateAvatar,
} from "../api/accountAction";
import { ValuesOfCorrectTypeRule } from "graphql";

const { Option } = Select;

const OnboardAvatar = () => {
  const companyName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.com")
  );
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [drawartype, setDrawarType] = useState("");
  const [buttonloading, setbuttonloading] = useState(false);
  const [records, setRecords] = useState([]);
  const [selectedrecords, setSelectedRecords] = useState([]);
  const [updatestatus, setUpdateStatus] = useState(false);
  const dispatch = useDispatch();

  const handleChange = (info) => {
    if (info.file.status === "done") {
      // Get the base64 value of the uploaded image
      const reader = new FileReader();
      reader.onload = () => {
        console.log("Base64 value:", reader.result);
      };
      reader.readAsDataURL(info.file.originFileObj);
    }
  };
  const [defaultImage, setDefaultImage] = useState();
  const showAddDrawer = () => {
    setDefaultImage();
    setOpen(true);
    setDrawarType("add");
    const records = {
      pod_category: "",
      avatar_title: "",
      about: "",
      context: "",
    };
    form.setFieldsValue(records);
  };
  const showEditRow = (index) => {
    dispatch(getAvatarDetails(index.id))
      .unwrap()
      .then(({ data }) => {
        console.log(data);
        setDefaultImage(
          JSON.parse(data.aivolvex_general_prompts)[0].avatar_image
        );
        setSelectedRecords(index);
        setOpen(true);
        setDrawarType("update");
        const records = {
          pod_category: index.pod,
          avatar_title: index.avatar_name,
          about: index.about_us,
          context: index.context,
          avatar_image: index.avatar_image,
          id: index.id,
          avatar_owner: index.avatar_owner,
          version: index.version,
        };
        form.setFieldsValue(records);
      })
      .catch((err) => {
        console.log(err);
        // message.error(err);
      });
  };
  const onClose = () => {
    form.resetFields();
    setDefaultImage();
    setOpen(false);
  };

  const columns = [
    {
      title: "Pod",
      dataIndex: "pod",
      key: "pod",
    },
    {
      title: "Avatar Title",
      dataIndex: "avatar_name",
      key: "avatar_name",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (record, index) => {
        return (
          <>
            <div
              onClick={() => showEditRow(index)}
              style={{ cursor: "pointer" }}
            >
              <Icon icon="material-symbols:edit" />
            </div>
          </>
        );
      },
    },
  ];
  const data = [
    {
      key: "1",
      pod: "test",
      avatar: 32,
    },
    {
      key: "2",
      pod: "test",
      avatar: 32,
    },
  ];
  useEffect(() => {
    const input = {
      company_name: companyName,
      tag_id: "all",
    };
    dispatch(listAvatar(input))
      .unwrap()
      .then(({ data }) => {
        // setUpdateStatus(!updatestatus);
        if (JSON.parse(data.aivolvex_general_prompts).length > 0) {
          setRecords(JSON.parse(data.aivolvex_general_prompts));
        } else {
          setRecords([]);
        }
      })
      .catch((err) => {
        console.log(err);
        console.log(err);
        message.error(err);
      });
  }, [updatestatus]);
  const handleSubmit = (values) => {
    setbuttonloading(true);

    if (drawartype === "add") {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result;
        console.log("Base64 value:", base64String);
        const input = {
          pod_category: values.pod_category,
          avatar_title: values.avatar_title,
          context: values.context,
          about: values.about,
          // prompts: JSON.stringify(values.prompts),
          image: base64String,
          avatar_id: values.id,
          avatar_owner: values.avatar_owner,
          version: values.version,
        };

        dispatch(onboardAvatar(input))
          .unwrap()
          .then(({ data }) => {
            setOpen(false);
            setbuttonloading(false);
            setUpdateStatus(!updatestatus);
            message.success("Avatar Added Successfully!");
          })
          .catch((err) => {
            console.log(err);
            message.error(err);
            setbuttonloading(false);
          });
      };

      let fileBlob;
      fileBlob = new Blob([values.image[0].originFileObj]);
      reader.readAsDataURL(fileBlob);
      // console.log(input);
      form.resetFields();
      setDefaultImage();
    } else {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result;
        console.log("Base64 value:", base64String);
        const input = {
          id: selectedrecords.id,
          pod_category: values.pod_category,
          avatar_title: values.avatar_title,
          context: values.context,
          about: values.about,
          avatar_id: values.id,
          // prompts: JSON.stringify(values.prompts),
          image: values.image ? base64String : defaultImage,
          avatar_owner: values.avatar_owner,
          version: values.version,
        };
        dispatch(updateAvatar(input))
          .unwrap()
          .then(({ data }) => {
            setOpen(false);
            setbuttonloading(false);
            setUpdateStatus(!updatestatus);
            message.success("Avatar Updated Successfully!");
          })
          .catch((err) => {
            console.log(err);
            message.error(err);
            setbuttonloading(false);
          });
        console.log(selectedrecords);
        console.log(input);
        form.resetFields();
        setDefaultImage();
      };

      if (values.image) {
        let fileBlob;
        fileBlob = new Blob([values.image[0].originFileObj]);
        reader.readAsDataURL(fileBlob);
      } else {
        const input = {
          id: selectedrecords.id,
          pod_category: values.pod_category,
          avatar_title: values.avatar_title,
          context: values.context,
          about: values.about,
          avatar_id: values.id,
          // prompts: JSON.stringify(values.prompts),
          image: defaultImage,
          avatar_owner: values.avatar_owner,
          version: values.version,
        };
        dispatch(updateAvatar(input))
          .unwrap()
          .then(({ data }) => {
            setOpen(false);
            setbuttonloading(false);
            setUpdateStatus(!updatestatus);
            message.success("Avatar Updated Successfully!");
          })
          .catch((err) => {
            console.log(err);
            message.error(err);
            setbuttonloading(false);
          });
        form.resetFields();
        setDefaultImage();
      }
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setDefaultImage();
    setOpen(false);
  };

  return (
    <>
      <Drawer
        title="Avatar Details"
        width={720}
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
        }}
      >
        <Form
          layout="vertical"
          hideRequiredMark
          onFinish={handleSubmit}
          form={form}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="pod_category"
                label="Pod Category"
                rules={[
                  {
                    required: true,
                    message: "Please enter pod category",
                  },
                ]}
              >
                <Input placeholder="Please enter pod category" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="avatar_title"
                label="Avatar Title"
                rules={[
                  {
                    required: true,
                    message: "Please enter avatar title",
                  },
                ]}
              >
                <Input placeholder="Please enter avatar title" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="version"
                label="Version"
                rules={[
                  {
                    required: true,
                    message: "Please enter Version",
                  },
                ]}
              >
                <Input placeholder="Please enter Version" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="avatar_owner"
                label="Avatar Owner"
                rules={[
                  {
                    required: true,
                    message: "Please enter avatar owner",
                  },
                ]}
              >
                <Input placeholder="Please enter avatar owner" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="about"
                label="About"
                rules={[
                  {
                    required: true,
                    message: "please enter about",
                  },
                ]}
              >
                <Input.TextArea rows={4} placeholder="please enter about" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="context"
                label="Context"
                rules={[
                  {
                    required: true,
                    message: "please enter context",
                  },
                ]}
              >
                <Input.TextArea rows={4} placeholder="please enter context" />
              </Form.Item>
            </Col>
          </Row>

          {/* <Row gutter={16}>
            <lable>Prompts Library</lable>
          </Row> */}
          {/* <Form.List name="prompts">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div
                    key={key}
                    // style={{
                    //   display: "flex",
                    //   marginBottom: 8,
                    // }}
                    // align="baseline"
                  >
                    <Row gutter={[16, 16]}>
                      <Col xxl={22} xl={22} lg={22} md={22} sm={22} xs={22}>
                        <Form.Item
                          {...restField}
                          name={[name, "prompts_library"]}
                          rules={[
                            {
                              required: true,
                              message: " ",
                            },
                          ]}
                        >
                          <Input placeholder="Prompts" />
                        </Form.Item>
                      </Col>

                      <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}>
                        <Icon
                          style={{
                            fontSize: "29px",
                            color: "red",
                            cursor: "pointer",
                          }}
                          icon="material-symbols:delete"
                          onClick={() => remove(name)}
                        />
                      </Col>
                    </Row>
                  
                  </div>
                ))}
                 
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<Icon icon="teenyicons:add-outline" />}
                  >
                    Add prompts library
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List> */}

          <Row gutter={[16, 16]}>
            <Col xxl={22} xl={22} lg={22} md={22} sm={22} xs={22}>
              <Form.Item
                name="image"
                label="Upload Your Avatar"
                valuePropName="fileList"
                getValueFromEvent={(e) => e && e.fileList}
                className="onboard_avatar_updated"
                rules={[
                  {
                    required: defaultImage ? false : true,
                    message: "Please upload an Avatar image!",
                  },
                ]}
              >
                <Upload
                  name="image"
                  listType="picture-card"
                  showUploadList={true}
                  beforeUpload={() => false} // Prevent actual file upload
                  onChange={handleChange}
                  maxCount={1} // Set this to the maximum number of files allowed (1 in this case)
                >
                  {defaultImage ? (
                    <img
                      src={defaultImage}
                      alt="Preview"
                      style={{ width: "100%", height: "100%" }}
                    />
                  ) : (
                    <div>
                      <UploadOutlined />
                      <div style={{ marginTop: 8 }}>Click to Upload</div>
                    </div>
                  )}
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item>
                <Button htmlType="button" onClick={() => handleCancel()}>
                  Cancel
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    background: "#1b5f9d",
                    color: "#fff",
                    float: "right",
                  }}
                  loading={buttonloading}
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>

      <NavBar />
      <div className="body-bg">
        <Row>
          <Col span={5} className="window-margin-right  body-margin-top">
            <Sidemenu />
          </Col>
          <Col span={18} className="body-margin-top">
            <Row>
              <Col span={24}>
                <Button
                  type="primary"
                  onClick={showAddDrawer}
                  icon={<Icon icon="material-symbols:add" />}
                >
                  New Avatar
                </Button>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Table
                  className="mt-3 ms-2 costimize-bootstrap-table"
                  columns={columns}
                  dataSource={records}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default memo(OnboardAvatar);
