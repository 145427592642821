import React, { useState } from "react";
import NavBar from "../Header/NavBar";
import Sidemenu from "../Sidemenu/Sidemenu";
import BreadCrumb from "../BreadCrumb/BreadCrumb";
import {
  Col,
  Row,
  Space,
  Table,
  Button,
  Modal,
  Form,
  Input,
  Popconfirm,
  message,
  Select,
} from "antd";
import { Icon } from "@iconify/react";
import "./rolehome.css";
import { useNavigate } from "react-router-dom";

const RoleAvatarConfig = () => {
  const navigateFn = useNavigate();
  const [open, setOpen] = useState(false);
  const [errorstatus, setErrorStatus] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleCancel = () => {
    setOpen(false);
  };

  const confirm = (e) => {
    console.log(e);
    message.success("Click on Yes");
  };

  const columns = [
    {
      title: "Avatars",
      dataIndex: "avatar",
      key: "avatar",
      align: "center",
      width: "60%",
    },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      width: "20%",
      align: "center",
      render: (text) => (
        <Space size="middle">
          <a>
            <Popconfirm
              title="Delete the task"
              description="Are you sure to delete this task?"
              onConfirm={confirm}
              //   onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <Icon
                icon="fluent:delete-20-regular"
                style={{ fontSize: "25px", color: "#fff" }}
              />
            </Popconfirm>
          </a>
        </Space>
      ),
    },
  ];
  const data = [
    {
      key: "1",
      avatar: "PMO Whisperer",
      id: "1",
    },
    {
      key: "2",
      avatar: "Support Mate",
      id: "1",
    },
  ];

  const onFinishRole = () => {
    console.log("in");
  };

  return (
    <>
      <NavBar />
      <div className="body-bg">
        <Row>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={5}
            xl={5}
            className="window-margin-right  body-margin-top"
          >
            <Sidemenu />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={18}
            xl={18}
            className="body-margin-top"
          >
            <Row className="mt-1">
              <BreadCrumb
                homelink="/role"
                breadcrumblist={[
                  {
                    name: "Role",
                    link: "/role",
                  },
                ]}
                activebreadcroum={{
                  name: "Avatar Config",
                  link: "/role",
                }}
              />
              <Col span={24} className="d-flex justify-content-between">
                <h4 className="white-color">Avatar Config</h4>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div className="text-right">
                  <Button
                    type="primary"
                    onClick={() => setOpen(true)}
                    icon={<Icon icon="material-symbols:add" />}
                    className="aivolvex-add-btn"
                  >
                    Config
                  </Button>
                </div>
                <Table
                  className="mt-3 ms-2 costimize-bootstrap-table"
                  dataSource={data}
                  columns={columns}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      {/* model */}
      <Modal
        title="Avatar Config"
        open={open}
        // onOk={handleOk}
        // confirmLoading={confirmLoading}
        onCancel={handleCancel}
        // visible={open}
        footer={null}
        maskClosable={false}
        className="login-azure-ad-model"
      >
        <Form onFinish={onFinishRole} className="mt-5">
          <Form.Item
            name="avatar"
            label="Avatars"
            className="lable-white"
            rules={[{ required: true, message: "Please input your Avatars" }]}
          >
            <Select
              mode="multiple"
              allowClear
              style={{
                width: "100%",
              }}
              placeholder="Please select"
              options={[
                {
                  label: "CloudShifu",
                  value: "256",
                },
                {
                  label: "CostDeMaze",
                  value: "257",
                },
              ]}
            />
          </Form.Item>
          <Form.Item className="text-right">
            <Button
              htmlType="submit"
              className="plat-login-button"
              loading={isSubmitting}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      {/* end model */}
    </>
  );
};

export default RoleAvatarConfig;
