import React from "react";
import "./SignInBanner.scss";
import signInCarousel1Image from "../../assets/banner/Banner_2.jpg";
function SignInCarousel1() {
  return (
    <div className="d-flex justify-content-center">
      <div className="text-black">
        <div className="text-center carousel-header carousel-text">
          <div>
            <img src={signInCarousel1Image} className="signInCarousel1Image" />
          </div>
          {/* <div className="siginbanner-heading siginbanner-1">
            Welcome to the Alvolution with AlVolveX.ai
          </div> */}
          {/* <p className="siginbanner-heading">
            {" "}
            Collaborate With Top-notch Talent Across the Globe{" "}
          </p> */}
        </div>
        {/* <div className="text-center mt-2 carousel-content">
          <p>
            Simplify your hiring process, save valuable time and unlock endless
          </p>
          <p>
            {" "}
            possibilities to meet all your business needs. Join Supergigs today
            and
          </p>
          <p> experience the difference!</p>
        </div> */}
      </div>
    </div>
  );
}

export default SignInCarousel1;
