import { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  getAvatarHistoryTitle,
  //  updatehistory, deletehistory 
} from "../api/accountAction";
import { Icon } from "@iconify/react";
import { useNavigate, useParams } from "react-router-dom";
import PromptsAboutScroll from "./PromptsAboutScroll";
import { GrEdit } from "react-icons/gr";
import { IoTrashBin } from "react-icons/io5";
import { BsChatRight } from "react-icons/bs";
import { setSessionId } from "../Slice/slice";
import { Input, Popconfirm } from "antd"

const HistoryPromptsList = (props) => {
  const navigateFn = useNavigate();
  const dispatch = useDispatch();
  const [promptslist, setPromptsList] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [editstatus, setEditstatus] = useState(Array(promptslist.length).fill(false));
  // const [editstatus, setEditstatus] = useState([]);
  const contentRef = useRef(null);
  const handleScroll = (scrollAmount) => {
    if (contentRef.current) {
      contentRef.current.scrollTop += scrollAmount;
    }
  };
  const userName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
  );

  const cancel = (e) => {
    console.log(e);

  };

  useEffect(() => {
    const input = {
      id: props.avatar_id,
      user_id: userName,
    };
    dispatch(getAvatarHistoryTitle(input))
      .unwrap()
      .then(({ data }) => {
        const records = JSON.parse(data.aivolvex_general_prompts);
        if (records.length > 0) {
          setPromptsList(records);
        } else {
          setPromptsList([]);
        }
      })
      .catch((err) => {
        console.log(err);
        // message.error(err);
      });
  }, [props]);
  const getPromptsContent = (conversation_id) => {
    // console.log("/library/" + props.avatar_id + "/" + conversation_id);
    navigateFn("/chat/" + props.avatar_id + "/" + conversation_id);
  };

  const [editIndex, setEditIndex] = useState(-1);
  useEffect(() => {
    if (editIndex !== -1) {
      setInputValue(promptslist[editIndex].title);
    }
  }, [editIndex, promptslist]);
  const handleEditClick = (index) => {
    const updatedEditStatus = [...editstatus];
    updatedEditStatus[index] = true;
    setEditstatus(updatedEditStatus);
    setEditIndex(index);
  };

  const handleApplyClick = (index, inputValue, id) => {
    console.log(id)
    console.log(inputValue)
    const updatedEditStatus = [...editstatus];
    updatedEditStatus[index] = false;
    setEditstatus(updatedEditStatus);
    setEditIndex(-1);
    const newData = [...promptslist];
    newData[index].title = inputValue;
    console.log(newData)
    setPromptsList(newData);
    // dispatch(updatehistory(inputValue,id))
    // .unwrap()
    //   .then(({ data }) => {
    //     const records = JSON.parse(data.aivolvex_general_prompts);

    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     // message.error(err);
    //   });
  };
  const handleCancelClick = (index) => {
    console.log(inputValue)
    const updatedEditStatus = [...editstatus];
    updatedEditStatus[index] = false;
    setEditstatus(updatedEditStatus);
    setEditIndex(-1);

  };
  const handleDeleteClick = (conversationId) => {
    // console.log(e);
    const updatedPromptsList = promptslist.filter((data) => data.conversation_id !== conversationId);
    setPromptsList(updatedPromptsList);
    // dispatch(deletehistory(inputValue,id))
    // .unwrap()
    //   .then(({ data }) => {
    //     const records = JSON.parse(data.aivolvex_general_prompts);

    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     // message.error(err);
    //   });
  };

  return (
    <>
      <div className="aivolvex-font">
        {/* <div className="text-center">
          <label className="prompt-tap-title">History </label>
        </div> */}
        <div className="side-scroll-container-prompts">
          {/* <button
            class="button-primary-questions  btn btn-primary fontSize10"
            role="button"
            onClick={() => {
              if (editstatus[0] === false) {
                dispatch(setSessionId(""));
                props.setSessionID("");
                // navigateFn("/chat/" + props.avatar_id);
                window.location.href = "/chat/" + props.avatar_id;
              }
            }}
          >
            <div className="btn-main-box d-flex justify-content-between">
              <div className="btn-prompts-content">
                <Icon
                  icon="ri:chat-new-line"
                  className="prompts-history-icon"
                />{" "}
                New Chat
              </div>
            </div>
          </button> */}
          {promptslist.map((data, index) => {
            const currentInputValue = editIndex === index ? inputValue : data.title;
            return (
              <>
                <div key={index}>
                  <button
                    class="button-primary-questions  btn btn-primary fontSize10"
                    role="button"

                  >
                    <div className="btn-main-box d-flex justify-content-between"
                    >
                      <div className="btn-prompts-content" onClick={() => {
                        getPromptsContent(data.conversation_id);
                      }}>
                        <span>
                          <BsChatRight className="prompts-history-icon" />
                        </span>
                        {editIndex === index ? (
                          <Input
                            value={currentInputValue}
                            bordered={false}
                            className="edit-input-filed"
                            onChange={(e) => setInputValue(e.target.value)}

                          />
                        ) : (
                          <>{currentInputValue ? (
                            <>{currentInputValue}</>
                          ) : (
                            <>
                              {data.title?.length > 70 ? (
                                <>{data.title?.substring(0, 70)}... </>
                              ) : (
                                <>{data.title}</>
                              )}
                            </>
                          )}

                          </>
                        )}
                      </div>
                      <div className="btn-prompts-token">
                        <span className="d-flex justify-content-between">
                          {editIndex === index ? (
                            <>
                              <span>
                                <Icon icon="charm:tick" className="prompts-history-icon" onClick={() => handleApplyClick(index, inputValue, data.conversation_id)} />
                              </span>
                              <span>
                                <Icon icon="ph:x-bold" className="prompts-history-icon" onClick={() => handleCancelClick(index)} />
                              </span>
                            </>
                          ) : (
                            <>
                              <span>
                                <Icon
                                  icon="wpf:edit"
                                  className="prompts-history-icon"
                                  onClick={() => handleEditClick(index)}
                                />
                              </span>
                              <span>
                                <Popconfirm
                                  title="Delete Chat?"
                                  onConfirm={() => handleDeleteClick(data.conversation_id)}

                                  onCancel={cancel}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <IoTrashBin className="prompts-history-icon" />
                                </Popconfirm>
                              </span>
                            </>
                          )}
                        </span>
                      </div>
                    </div>
                  </button>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default HistoryPromptsList;
