import { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { getAvatarFrequentlyPrompts } from "../api/accountAction";
import { Icon } from "@iconify/react";
import PromptsAboutScroll from "./PromptsAboutScroll";
import aivolvex from "../../assets/ui/X Icon SVG.svg";

const FrequentlyPromptsList = (props) => {
  const dispatch = useDispatch();
  const contentRef = useRef(null);
  const tag_id = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.tags")
  );
  const handleScroll = (scrollAmount) => {
    if (contentRef.current) {
      contentRef.current.scrollTop += scrollAmount;
    }
  };
  const userName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
  );
  const [promptslist, setPromptsList] = useState([]);
  useEffect(() => {
    const input = {
      id: props.avatar_id,
      user_id: userName,
    };
    // console.log(input);
    dispatch(getAvatarFrequentlyPrompts(input))
      .unwrap()
      .then(({ data }) => {
        // console.log(data);
        const records = JSON.parse(data.aivolvex_general_prompts);
        const filter_records = records.filter(
          (data_check) =>
            data_check.message !== undefined &&
            data_check.message !== null &&
            data_check.message !== " "
        );
        if (filter_records.length > 0) {
          setPromptsList(filter_records);
        } else {
          setPromptsList([]);
        }
      })
      .catch((err) => {
        console.log(err);
        // message.error(err);
      });
  }, [props.frequentlypromptsstatus]);
  return (
    <>
      {/* <PromptsAboutScroll> */}
      <div className="aivolvex-font">
        {/* <div className="text-center">
          <label className="prompt-tap-title">Frequently Used Prompts </label>
        </div> */}
        <div className="side-scroll-container-prompts">
          {promptslist.map((data) => {
            return (
              <button
                class="button-primary-questions  btn btn-primary fontSize10"
                role="button"
                onClick={() => {
                  props.getPromptsContent(data.message);
                }}
              >
                <div className="btn-main-box d-flex justify-content-between">
                  <div className="btn-prompts-content">{data.message}</div>
                  {tag_id === "all" && (
                    <>
                      <div className="btn-prompts-token">
                        <span className="d-flex justify-content-between">
                          <span>100</span>{" "}
                          <img
                            src={aivolvex}
                            alt="logo"
                            className="token-footer-logo"
                          />
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </button>
            );
          })}
        </div>
      </div>
      {/* </PromptsAboutScroll> */}
    </>
  );
};

export default FrequentlyPromptsList;
