import React, { useState } from "react";
import NavBar from "../Header/NavBar";
import Sidemenu from "../Sidemenu/Sidemenu";
import {
  Col,
  Row,
  Table,
  Button,
  Modal,
  Alert,
  Form,
  Input,
  Popover,
  Card,
  message,
  Popconfirm,
  Space
} from "antd";
import { IoTrashBin } from "react-icons/io5";
import { Icon } from "@iconify/react";
import "./rolehome.css";
import { useNavigate } from "react-router-dom";
import BreadCrumb from "../BreadCrumb/BreadCrumb";

const RoleHome = () => {
  const navigateFn = useNavigate();
  const [open, setOpen] = useState(false);
  const [errorstatus, setErrorStatus] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [visible, setVisible] = useState(false);
  const [submittedData, setSubmittedData] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [editValue, setEditValue] = useState("");
  const data = [
    {
      key: "1",
      tag: "Super Admin",
      id: "1",
    },
    {
      key: "2",
      tag: "Admin",
      id: "1",
    },
  ];
  const [tagdata, setTagdata] = useState([
    {
      key: "1",
      tag: "Member1",
      id: "1",
    },
    {
      key: "2",
      tag: "Member2",
      id: "1",
    },
    {
      key: "3",
      tag: "Member3",
      id: "1",
    },
  ]);
  let iconvalue = "12"
  const handleCancel = () => {
    setOpen(false);
    setMemberpopup(false)
    setEditIndex(null);
    setSubmittedData([])
    
  };
  const [memberpopup, setMemberpopup] = useState(false)
  const handlememberpopup = () => {
    setMemberpopup(true)
    const tagValues = tagdata.map((item) => item.tag);
    setSubmittedData(tagValues);

  }

  const columns = [
    {
      title: (
        <>
          <Icon icon="fluent:tag-28-regular" /> Tags
        </>
      ),
      dataIndex: "tag",
      key: "tag",
      align: "center",
      width: "60%",
    },
    {
      title: (
        <>
          <Icon icon="ion:add" /> Members
        </>
      ),
      dataIndex: "members",
      key: "members",
      width: "20%",
      align: "center",
      render: (text) => (
        <div className="d-flex justify-content-center">
          <Popover
            content={content}
            title="Member"
            trigger="click"
            placement="bottom"
          >
            <Icon
              icon="material-symbols:group-add-outline"
              style={{ fontSize: "25px", color: "#fff" }}
              className="cursor-pointer"
            />
          </Popover>
          {" "}  {" "}

          <div className="d-flex tag-members-body">
            <div className="icon-with-badge" onClick={handlememberpopup}>
              <Icon icon="fluent-mdl2:entry-view" />
              <div className="badge">{iconvalue <= 9 ? <>{""} {iconvalue}</> : '10+'}</div>
            </div>
          </div>
        </div>
      ),
    },
  ];

  
  const onFinishRole = (values) => {
    // setIsSubmitting(true); // Start loading state
    // // Simulate an API call or perform any necessary actions here
    // setTimeout(() => {
    //   const tagValues = data.map((item) => item.tag);
    //   setSubmittedData(tagValues);
    //   setIsSubmitting(false); // Stop loading state
    // }, 1000); // Simulate a delay for demonstration
  };
  const handleEdit = (index) => {
    setEditIndex(index);
  };

  const handleDelete = (tag) => {
    console.log("Delete");
    const updatedData = submittedData.filter((item) => item !== tag);
    setSubmittedData(updatedData);
  };
  const confirm = (e) => {
    console.log(e);
  };
  const cancel = (e) => {
    console.log(e);
  };
  const handleApply = (index, editedValue) => {
    const updatedData = [...submittedData];
    updatedData[index] = editValue;
    setSubmittedData(updatedData);
    console.log(updatedData);
    setEditIndex(null);
  };
  // const handleApply = (index, editedValue) => {
  //   const updatedData = [...data]; // Create a copy of the original data array
  //   updatedData[index].tag = editedValue; // Update the 'tag' value at the specified index
  //   console.log(updatedData);
  
  //   // If you want to update 'submittedData' as well, you can do it here
  //   setSubmittedData(updatedData);
  
  //   setEditIndex(null);
  // };
  const onMembersubmit = () => {
    console.log("in");
    setIsSubmitting(true); 
    setTimeout(() => {
      const tagValues = data.map((item) => item.tag);
      setSubmittedData(tagValues);
      setIsSubmitting(false); 
    }, 1000); 
  }

  // const onFinishRole = () => {
  //   console.log("in");
  // };
  const [valueArray, setValueArray] = useState([]);
  const onFinishMember = (value) => {
    console.log(value);
      setValueArray([...valueArray, value]);
  };
  // const onFinishMember = (value) => {

  //   const newValue = {
  //     key: (tagdata.length + 1).toString(),
  //     tag: value,
  //     id: (tagdata.length + 1).toString(),
  //   };

   
  //   setTagdata([...tagdata, newValue]);

  //   // // Reset the input field
  //   // setInputValue('');
  // };

  // console.log(valueArray);

  const content = (
    <Form onFinish={onFinishMember}>
      <Form.Item
        name="name"
        rules={[{ required: true, message: "Please Enter Members" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" className="aivolvex-add-btn">
          Add
        </Button>
      </Form.Item>
    </Form>
  );
  const handlerole =()=>{
    const tagValues = data.map((item) => item.tag);
    setSubmittedData(tagValues);
    setIsSubmitting(false); 
    setOpen(true)
  }

  return (
    <>
      <NavBar />
      <div className="body-bg">
        <Row>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={5}
            xl={5}
            className="window-margin-right  body-margin-top"
          >
            <Sidemenu />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={18}
            xl={18}
            className="body-margin-top"
          >
            <Row className="mt-1">
              <BreadCrumb
                homelink="/tag"
                breadcrumblist={[]}
                activebreadcroum={{
                  name: "Tags",
                  link: "/tag",
                }}
              />
              <Col span={24} className="d-flex justify-content-between">
                <h4 className="white-color">Tags</h4>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div className="text-right">
                  <Button
                    type="primary"
                    onClick={handlerole}
                    // onClick={() => setOpen(true)}
                    icon={<Icon icon="material-symbols:add" />}
                    className="aivolvex-add-btn"
                  >
                    New Role
                  </Button>
                </div>
                <Table
                  className="mt-3 ms-2 costimize-bootstrap-table"
                  dataSource={data}
                  columns={columns}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      {/* model */}
      <Modal
        title="New Role"
        open={open}
        // onOk={handleOk}
        // confirmLoading={confirmLoading}
        onCancel={handleCancel}
        // visible={open}
        footer={null}
        maskClosable={false}
        className="login-azure-ad-model"
      >
        {errorstatus && (
          <Alert message="Role Name Already Exists" type="error" closable />
        )}
        {/* 
        <Form onFinish={onFinishRole} className="mt-5">
          <Form.Item
            name="role"
            label="Role"
            className="lable-white"
            rules={[{ required: true, message: "Please input your Role!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item className="text-right">
            <Button
              htmlType="submit"
              className="plat-login-button"
              loading={isSubmitting}
            >
              Submit
            </Button>
          </Form.Item>
          <Form.Item>
           <Input placeholder="Borderless" bordered={false} />;
          </Form.Item>
        </Form> */}
        <div>
          <Form onFinish={onFinishRole} className="mt-5">
            <Form.Item
              name="role"
              label="Role"
              className="lable-white"
              rules={[{ required: true, message: "Please input your Role!" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item className="text-right">
              <Button
                htmlType="submit"
                className="plat-login-button cursor-pointer"
                loading={isSubmitting}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>

          {submittedData.length > 0 && (
            <div className="mt-3">

              <ul style={{ gap: '10px' }} className="tag-card">
                {submittedData.map((tag, index) => (
                  <li key={index}>
                    <Card className="tag-list-card">
                      <Space >

                        {editIndex === index ? (
                          <>
                            <Input className="tag-data" defaultValue={tag} bordered={false} onChange={(e) => setEditValue(e.target.value)} />
                          </>
                        ) : (
                          <>
                            <div className="tag-data">{tag}</div>
                          </>
                        )}
                        <div className="tag-icon">
                          {editIndex === index ? (
                            <Icon
                              icon="charm:square-tick"
                              onClick={() => handleApply(index, tag)}
                              className="prompts-history-icon"
                            />

                          ) : (
                            <>
                              <Icon
                                icon="wpf:edit"
                                onClick={() => handleEdit(index)}
                                className="prompts-history-icon"
                              />
                              {" "}
                              <Popconfirm
                                title="Delete the task"
                                description="Are you sure to delete this task?"
                                onConfirm={() => handleDelete(tag)}
                                onCancel={cancel}
                                okText="Yes"
                                cancelText="No"
                              >
                                <IoTrashBin
                                  className="prompts-history-icon"
                                  onClick={(e) => e.stopPropagation()}
                                />
                              </Popconfirm> 
                              </>

                          )}
                        </div>
                      </Space>
                    </Card>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </Modal>
      {/* end model */}
     
      <Modal
        title="Member List"
        open={memberpopup}
        onCancel={handleCancel}
        footer={null}
        maskClosable={false}
        className="login-azure-ad-model"
      >
        <div>
          {/* <Form onFinish={onMembersubmit} className="mt-5">
            <Form.Item
              name="member"
              label="Member"
              className="lable-white"
              rules={[{ required: true, message: "Please input your Member!" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item className="text-right">
              <Button
                htmlType="submit"
                className="plat-login-button cursor-pointer"
                loading={isSubmitting}
              >
                Submit
              </Button>
            </Form.Item>
          </Form> */}

          <div className="mt-3">
            <ul style={{ gap: '10px' }} className="tag-card"  >
              {submittedData.map((tag, index) => (
                <li key={index} >
                   <Card className="tag-list-card">
                    <Space>
                      {editIndex === index ? (
                        <>
                          <Input
                            className="tag-data"
                            defaultValue={tag}
                            bordered={false}
                            onChange={(e) => setEditValue(e.target.value)}
                          />
                        </>
                      ) : (
                        <>
                          <div className="tag-data">{tag}</div>
                        </>
                      )}
                      <div className="tag-icon">
                        {editIndex === index ? (
                          <Icon
                            icon="charm:square-tick"
                            onClick={() => handleApply(index, tag)}
                            className="prompts-history-icon cursor-pointer"
                          />
                        ) : (
                          <>
                            {/* <Icon
                              icon="wpf:edit"
                              onClick={() => handleEdit(index)}
                              className="prompts-history-icon"
                            /> */}
                            {" "}
                            <Popconfirm
                              title="Delete the task"
                              description="Are you sure to delete this task?"
                              onConfirm={() => handleDelete(tag)}
                              onCancel={cancel}
                              okText="Yes"
                              cancelText="No"
                            >
                              <IoTrashBin
                                className="prompts-history-icon cursor-pointer"
                                onClick={(e) => e.stopPropagation()}
                              // onClick={() => handleDelete(tag)}
                              />
                            </Popconfirm>

                          </>
                        )}
                      </div>
                    </Space>
                  </Card>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Modal>

    </>
  );
};

export default RoleHome;
